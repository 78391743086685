import React from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

const Resources = () => {
  return (
    <div className="min-h-screen bg-[#F8FAFC]">
      <Navbar />
      <main className="container mx-auto px-6 py-16">
        <h1 className="text-4xl font-bold text-[#061631] mb-8">Resources</h1>
        <p className="text-lg text-gray-600">
          Explore our comprehensive collection of healthcare technology resources, including whitepapers, case studies, and industry insights.
        </p>
        {/* Add more content here */}
      </main>
      <Footer />
    </div>
  );
};

export default Resources; 