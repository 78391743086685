// App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ScrollToTop from './components/ScrollToTop';
import LandingPage from './pages/LandingPage';
import AboutUs from './pages/AboutUs';
import ClineraPlatform from './pages/ClineraPlatform';
import SuccessStory from './pages/SuccessStory';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsOfService from './pages/TermsOfService';
import CookiePolicy from './pages/CookiePolicy';
import CookieConsent from './components/CookieConsent';
import SuccessStories from './pages/SuccessStories';
import BlogsPage from './pages/BlogsPage';
import SingleBlogPage from './pages/SingleBlogPage';
import Resources from './pages/Resources';
import Careers from './pages/Careers';
import PatientRegistry from './pages/PatientRegistry';
import HealthcareAI from './pages/HealthcareAI';
import DataAnalytics from './pages/DataAnalytics';
import FHIRIntegration from './pages/FHIRIntegration';
import CustomSolutions from './pages/CustomSolutions';
import ContactUs from './pages/ContactUs';

const App = () => {
  return (
    <Router>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/clinera" element={<ClineraPlatform />} />
        <Route path="/success-stories" element={<SuccessStories />} />
        <Route path="/success-stories/iclaim" element={<SuccessStory />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-of-service" element={<TermsOfService />} />
        <Route path="/cookie-policy" element={<CookiePolicy />} />
        <Route path="/blog" element={<BlogsPage />} />
        <Route path="/blog/:slug" element={<SingleBlogPage />} />
        <Route path="/resources" element={<Resources />} />
        <Route path="/careers" element={<Careers />} />
        <Route path="/solutions/patient-registry" element={<PatientRegistry />} />
        <Route path="/solutions/healthcare-ai" element={<HealthcareAI />} />
        <Route path="/solutions/data-analytics" element={<DataAnalytics />} />
        <Route path="/solutions/fhir-integration" element={<FHIRIntegration />} />
        <Route path="/solutions/custom-solutions" element={<CustomSolutions />} />
        <Route path="/contact" element={<ContactUs />} />
      </Routes>
      <CookieConsent />
    </Router>
  );
};

export default App;