import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Menu, X } from 'lucide-react';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="absolute top-0 left-0 right-0 z-50">
      <div className="flex items-center justify-between container mx-auto px-4 sm:px-6 lg:px-8 py-6">
        <div className="flex items-center">
          <Link to="/" className="text-2xl font-bold bg-gradient-to-r from-white to-white/90 bg-clip-text text-transparent font-['Montserrat',_sans-serif]">
            EVEREST MINDS
          </Link>
        </div>

        {/* Mobile menu button */}
        <button
          onClick={toggleMenu}
          className="md:hidden text-white/80 hover:text-[#5BDB94] transition-colors"
        >
          {isOpen ? <X size={24} /> : <Menu size={24} />}
        </button>

        {/* Desktop menu */}
        <div className="hidden md:flex items-center space-x-8">
          <Link to="/" className="text-white/80 hover:text-[#5BDB94] transition-colors">
            Home
          </Link>
          <Link to="/about" className="text-white/80 hover:text-[#5BDB94] transition-colors">
            About Us
          </Link>
          <Link to="/clinera" className="text-white/80 hover:text-[#5BDB94] transition-colors">
            Clinera Platform
          </Link>
          <Link to="/blog" className="text-white/80 hover:text-[#5BDB94] transition-colors">
            Blog
          </Link>
          <Link to="/success-stories" className="text-white/80 hover:text-[#5BDB94] transition-colors">
            Success Stories
          </Link>
          <Link to="/contact" className="px-6 py-2 bg-[#5BDB94] text-[#0A1F44] rounded-lg font-medium hover:bg-[#4cc584] transition-colors">
            Contact Us
          </Link>
        </div>
      </div>

      {/* Mobile menu dropdown */}
      <div className={`md:hidden ${isOpen ? 'block' : 'hidden'} bg-[#0A1F44]/95 backdrop-blur-sm border-t border-white/10`}>
        <div className="container mx-auto px-4 py-4">
          <div className="flex flex-col space-y-4">
            <Link to="/" className="text-white/80 hover:text-[#5BDB94] transition-colors py-2">
              Home
            </Link>
            <Link to="/about" className="text-white/80 hover:text-[#5BDB94] transition-colors py-2">
              About Us
            </Link>
            <Link to="/clinera" className="text-white/80 hover:text-[#5BDB94] transition-colors py-2">
              Clinera Platform
            </Link>
            <Link to="/blog" className="text-white/80 hover:text-[#5BDB94] transition-colors py-2">
              Blog
            </Link>
            <Link to="/success-stories" className="text-white/80 hover:text-[#5BDB94] transition-colors py-2">
              Success Stories
            </Link>
            <Link to="/contact" className="px-6 py-2 bg-[#5BDB94] text-[#0A1F44] rounded-lg font-medium hover:bg-[#4cc584] transition-colors w-full text-center">
              Contact Us
            </Link>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar; 