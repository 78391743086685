import React, { useState, useEffect } from 'react';
import { ArrowRight, ChevronRight, ChevronLeft, Database, Cloud, Shield, Activity } from 'lucide-react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import { Link } from 'react-router-dom';

const CircularAnimation = ({ active }) => (
  <div className={`absolute right-[-100px] top-[100px] w-[700px] h-[700px] transition-opacity duration-700 ${active ? 'opacity-100' : 'opacity-0'}`}>
    <div className="relative w-full h-full">
      {/* Healthcare Elements */}
      <div className="absolute inset-0">
        {/* Hospital/Clinic Node */}
        <div className="absolute top-[20%] left-[20%] flex flex-col items-center">
          <div className="w-16 h-16 rounded-lg bg-[#0A1F44] border-2 border-[#5BDB94] flex items-center justify-center">
            <div className="text-[#5BDB94] text-2xl">H</div>
          </div>
          <span className="mt-2 text-[#5BDB94]/80 text-sm">Hospitals</span>
        </div>

        {/* Patient Data Node */}
        <div className="absolute top-[20%] right-[20%] flex flex-col items-center">
          <div className="w-16 h-16 rounded-lg bg-[#0A1F44] border-2 border-[#5BDB94] flex items-center justify-center">
            <div className="text-[#5BDB94] text-2xl">P</div>
          </div>
          <span className="mt-2 text-[#5BDB94]/80 text-sm">Patients</span>
        </div>

        {/* Research Node */}
        <div className="absolute bottom-[20%] left-[20%] flex flex-col items-center">
          <div className="w-16 h-16 rounded-lg bg-[#0A1F44] border-2 border-[#5BDB94] flex items-center justify-center">
            <div className="text-[#5BDB94] text-2xl">R</div>
          </div>
          <span className="mt-2 text-[#5BDB94]/80 text-sm">Research</span>
        </div>

        {/* Analytics Node */}
        <div className="absolute bottom-[20%] right-[20%] flex flex-col items-center">
          <div className="w-16 h-16 rounded-lg bg-[#0A1F44] border-2 border-[#5BDB94] flex items-center justify-center">
            <div className="text-[#5BDB94] text-2xl">A</div>
          </div>
          <span className="mt-2 text-[#5BDB94]/80 text-sm">Analytics</span>
        </div>

        {/* Central Platform */}
        <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
          <div className="w-24 h-24 rounded-full bg-[#0A1F44] border-2 border-[#5BDB94] flex items-center justify-center">
            <div className="text-[#5BDB94] text-sm text-center">Digital Health Platform</div>
          </div>
        </div>

        {/* Animated Connection Lines */}
        <svg className="absolute inset-0" width="100%" height="100%">
          {/* Data Flow Paths */}
          <path
            d="M250,150 Q350,250 350,350"
            fill="none"
            stroke="#5BDB94"
            strokeWidth="2"
            strokeDasharray="4,4"
            className="animate-dash-slow"
          />
          <path
            d="M450,150 Q350,250 350,350"
            fill="none"
            stroke="#5BDB94"
            strokeWidth="2"
            strokeDasharray="4,4"
            className="animate-dash-slow"
          />
          <path
            d="M250,550 Q350,350 450,550"
            fill="none"
            stroke="#5BDB94"
            strokeWidth="2"
            strokeDasharray="4,4"
            className="animate-dash-slow"
          />

          {/* Animated Data Points */}
          {[...Array(4)].map((_, i) => (
            <circle
              key={i}
              r="4"
              fill="#5BDB94"
              className="animate-flow-path"
              style={{
                animationDelay: `${i * 0.5}s`
              }}
            >
              <animateMotion
                dur="3s"
                repeatCount="indefinite"
                path="M250,150 Q350,250 350,350"
              />
            </circle>
          ))}
        </svg>
      </div>
    </div>
  </div>
);

const AIAnimation = ({ active }) => (
  <div className={`absolute right-[-100px] top-[100px] w-[700px] h-[700px] transition-opacity duration-700 ${active ? 'opacity-100' : 'opacity-0'}`}>
    <div className="relative w-full h-full">
      <svg width="100%" height="100%" viewBox="0 0 700 700">
        {/* AI Processing Flow */}
        <g className="animate-pulse-opacity">
          {/* Data Input Streams */}
          <path
            d="M100,350 C200,350 250,200 350,200"
            fill="none"
            stroke="#5BDB94"
            strokeWidth="2"
            strokeDasharray="4,4"
            className="animate-dash-slow"
          />
          <path
            d="M600,350 C500,350 450,200 350,200"
            fill="none"
            stroke="#5BDB94"
            strokeWidth="2"
            strokeDasharray="4,4"
            className="animate-dash-slow"
          />

          {/* AI Processing Center */}
          <circle
            cx="350"
            cy="350"
            r="60"
            fill="#0A1F44"
            stroke="#5BDB94"
            strokeWidth="2"
            className="animate-pulse-scale"
          />

          {/* Output Streams */}
          <path
            d="M350,500 C450,500 500,350 600,350"
            fill="none"
            stroke="#5BDB94"
            strokeWidth="2"
            strokeDasharray="4,4"
            className="animate-dash-slow"
          />
          <path
            d="M350,500 C250,500 200,350 100,350"
            fill="none"
            stroke="#5BDB94"
            strokeWidth="2"
            strokeDasharray="4,4"
            className="animate-dash-slow"
          />

          {/* Animated Data Points */}
          {[...Array(6)].map((_, i) => (
            <circle
              key={i}
              r="4"
              fill="#5BDB94"
              className="animate-flow-path"
              style={{
                animationDelay: `${i * 0.5}s`
              }}
            >
              <animateMotion
                dur="3s"
                repeatCount="indefinite"
                path="M100,350 C200,350 250,200 350,200"
              />
            </circle>
          ))}
        </g>
      </svg>
    </div>
  </div>
);

const ClientLogos = () => (
  <div className="relative z-20 py-16 border-t border-white/10">
    <div className="container mx-auto px-6">
      <p className="text-center text-white/60 mb-12 text-sm font-medium uppercase tracking-wider">
        Trusted by Industry Leaders
      </p>
      <div className="grid grid-cols-2 md:grid-cols-4 gap-12 items-center justify-items-center max-w-4xl mx-auto">
        {['Amgen', 'Roche', 'Pfizer', 'AstraZeneca'].map((client) => (
          <div key={client}
            className="text-white/40 hover:text-white/90 transition-all transform hover:scale-105 cursor-pointer font-semibold text-lg">
            {client}
          </div>
        ))}
      </div>
    </div>
  </div>
);

const slides = [
  {
    id: 1,
    title: "Digital Transformation Partner in Healthcare",
    subtitle: "LEADING DIGITAL HEALTH INNOVATION",
    description: "Empowering pharmaceutical & life sciences companies with cutting-edge digital solutions through our flagship platform",
    highlight: "Clinera",
    Animation: CircularAnimation,
    image: "https://images.unsplash.com/photo-1576091160399-112ba8d25d1d?ixlib=rb-1.2.1&auto=format&fit=crop&w=1200&q=80",
    useAnimation: false,
  },
  {
    id: 2,
    title: "Meet Our Healthcare AI Assistant",
    subtitle: "INTELLIGENT AUTOMATION",
    description: "Experience the future of healthcare with our AI-powered assistant, delivering insights and automation across your digital health journey",
    Animation: AIAnimation,
    image: "https://images.unsplash.com/photo-1584036561566-baf8f5f1b144?ixlib=rb-1.2.1&auto=format&fit=crop&w=1200&q=80",
    useAnimation: false,
  }
];

const Slider = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isTransitioning, setIsTransitioning] = useState(false);

  const nextSlide = () => {
    if (!isTransitioning) {
      setIsTransitioning(true);
      setCurrentSlide((prev) => (prev + 1) % slides.length);
      setTimeout(() => setIsTransitioning(false), 700);
    }
  };

  const prevSlide = () => {
    if (!isTransitioning) {
      setIsTransitioning(true);
      setCurrentSlide((prev) => (prev - 1 + slides.length) % slides.length);
      setTimeout(() => setIsTransitioning(false), 700);
    }
  };

  useEffect(() => {
    const timer = setInterval(nextSlide, 5000);
    return () => clearInterval(timer);
  }, []);

  return (
    <div className="relative min-h-screen overflow-hidden">
      {/* Background gradients */}
      <div className="absolute inset-0 bg-[#0A1F44]">
        <div className="absolute inset-0 opacity-30 bg-gradient-to-r from-[#5BDB94]/20 via-transparent to-transparent" />
        <div className="absolute inset-0 bg-gradient-to-b from-transparent via-transparent to-[#0A1F44]" />
      </div>

      {/* Content */}
      <div className="relative z-10">
        {slides.map((slide, index) => (
          <div
            key={slide.id}
            className={`absolute inset-0 transition-opacity duration-700 
              ${index === currentSlide ? 'opacity-100' : 'opacity-0 pointer-events-none'}`}
          >
            <div className="container mx-auto px-6 pt-32 relative z-20">
              <div className="max-w-2xl relative">
                <div className="mb-6 inline-block py-2 px-4 bg-gradient-to-r from-[#5BDB94]/20 to-transparent rounded-full backdrop-blur-sm">
                  <span className="text-[#5BDB94] font-semibold tracking-wider">
                    {slide.subtitle}
                  </span>
                </div>
                <h1 className="text-4xl md:text-7xl font-bold text-white leading-tight mb-8 relative backdrop-blur-sm">
                  {slide.title}
                </h1>
                <p className="text-lg md:text-xl text-white/80 mb-12 relative backdrop-blur-sm">
                  {slide.description}
                  {slide.highlight && (
                    <span className="text-[#5BDB94] font-semibold"> {slide.highlight}</span>
                  )}
                </p>
                <div className="flex flex-col sm:flex-row gap-4 relative backdrop-blur-sm">
                  <Link to="/contact" className="group px-8 py-4 bg-[#5BDB94] text-[#0A1F44] rounded-lg font-semibold hover:bg-[#4cc584] transition-all transform hover:-translate-y-1 text-center">
                    <span className="flex items-center justify-center">
                      Experience Our Solutions
                      <ArrowRight className="ml-2 w-5 h-5 group-hover:translate-x-1 transition-transform" />
                    </span>
                  </Link>
                  <button className="px-8 py-4 border border-[#5BDB94] text-[#5BDB94] rounded-lg font-semibold hover:bg-[#5BDB94]/10 transition-all text-center">
                    Watch Demo
                  </button>
                </div>
              </div>
            </div>
            {slide.useAnimation ? (
              <slide.Animation active={index === currentSlide} />
            ) : (
              <div className={`absolute right-0 md:right-[-100px] top-0 md:top-[100px] w-full md:w-[700px] h-[100vh] md:h-[700px] transition-opacity duration-700 ${index === currentSlide ? 'opacity-100' : 'opacity-0'} -z-10`}>
                <div className="relative w-full h-full">
                  <div className="absolute inset-0 bg-gradient-to-t from-[#0A1F44] via-transparent to-transparent md:bg-none"></div>
                  <img
                    src={slide.image}
                    alt={slide.title}
                    className="w-full h-full object-cover md:rounded-2xl"
                    style={{
                      maskImage: 'linear-gradient(to left, rgba(0,0,0,1), rgba(0,0,0,0))',
                      WebkitMaskImage: 'linear-gradient(to left, rgba(0,0,0,1), rgba(0,0,0,0))'
                    }}
                  />
                  <div className="absolute inset-0 bg-gradient-to-r from-[#0A1F44] via-[#0A1F44]/30 to-[#0A1F44]/50 md:rounded-2xl" />
                </div>
              </div>
            )}
          </div>
        ))}

        {/* Slider Navigation */}
        <div className="absolute bottom-8 left-1/2 -translate-x-1/2 flex items-center gap-4">
          <button onClick={prevSlide} className="p-2 text-white/60 hover:text-white transition-colors">
            <ChevronLeft className="w-6 h-6" />
          </button>
          {slides.map((_, index) => (
            <button
              key={index}
              onClick={() => setCurrentSlide(index)}
              className={`w-3 h-3 rounded-full transition-all ${index === currentSlide ? 'bg-[#5BDB94] w-8' : 'bg-white/30 hover:bg-white/50'
                }`}
            />
          ))}
          <button onClick={nextSlide} className="p-2 text-white/60 hover:text-white transition-colors">
            <ChevronRight className="w-6 h-6" />
          </button>
        </div>
      </div>
    </div>
  );
};

const TechStackVisual = () => (
  <div className="relative w-full h-[600px]">
    {/* Main platform circle */}
    <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-[300px] h-[300px]">
      <div className="absolute inset-0 rounded-full border-2 border-[#5BDB94]/30 animate-spin-slow">
        {/* Circle border only */}
      </div>

      {/* Central platform text */}
      <div className="absolute inset-0 flex items-center justify-center">
        <div className="text-center">
          <div className="text-[#5BDB94] font-bold text-xl mb-2">Clinera</div>
          <div className="text-white/70 text-sm">Next-Gen Platform</div>
        </div>
      </div>
    </div>

    {/* Orbiting technology nodes */}
    {[
      { label: 'FHIR API', icon: Database },
      { label: 'Cloud Native', icon: Cloud },
      { label: 'HL7 Integration', icon: Activity },
      { label: 'HIPAA Compliant', icon: Shield },
      { label: 'OpenEHR', icon: Database },
      { label: 'RESTful APIs', icon: Cloud },
      { label: 'Microservices', icon: Activity },
      { label: 'JWT Auth', icon: Shield }
    ].map((tech, i) => {
      const angle = (i * Math.PI * 2) / 8;
      const radius = 200;
      const x = Math.cos(angle) * radius;
      const y = Math.sin(angle) * radius;

      return (
        <div
          key={tech.label}
          className="absolute flex flex-col items-center transition-all group cursor-pointer"
          style={{
            left: `calc(50% + ${x}px)`,
            top: `calc(50% + ${y}px)`,
            transform: 'translate(-50%, -50%)'
          }}
        >
          <div className="p-3 bg-[#0A1F44] border border-[#5BDB94]/30 rounded-lg group-hover:border-[#5BDB94] transition-colors">
            <tech.icon className="w-6 h-6 text-[#5BDB94]" />
          </div>
          <span className="mt-2 text-white/70 text-sm group-hover:text-white transition-colors">
            {tech.label}
          </span>
        </div>
      );
    })}
  </div>
);

const FeatureCard = ({ title, description, icon: Icon }) => (
  <div className="p-6 bg-white/5 rounded-lg border border-white/10 hover:border-[#5BDB94]/30 transition-colors group">
    <div className="w-12 h-12 rounded-lg bg-[#5BDB94]/10 flex items-center justify-center mb-4 group-hover:bg-[#5BDB94]/20 transition-colors">
      <Icon className="w-6 h-6 text-[#5BDB94]" />
    </div>
    <h3 className="text-xl font-semibold text-white mb-3">{title}</h3>
    <p className="text-white/70">{description}</p>
  </div>
);

const ClineraPlatform = () => (
  <section className="py-24 relative overflow-hidden">
    <div className="container mx-auto px-6">
      <div className="grid md:grid-cols-2 gap-16 items-center">
        <div>
          <div className="inline-block py-2 px-4 bg-[#5BDB94]/10 rounded-full mb-6">
            <span className="text-[#5BDB94] font-semibold">CLINERA PLATFORM</span>
          </div>
          <h2 className="text-4xl font-bold text-white mb-6">
            Next-Generation Medical Practice Software
          </h2>
          <p className="text-xl text-white/70 mb-8">
            Advanced patient registry and medical practice platform designed specifically for rare diseases and specialized care workflows.
          </p>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-12">
            <FeatureCard
              title="Patient Registries"
              description="Comprehensive patient data management for rare diseases and specialized conditions"
              icon={Database}
            />
            <FeatureCard
              title="Practice Management"
              description="Streamlined workflows designed for specialty practices and clinics"
              icon={Activity}
            />
            <FeatureCard
              title="Interoperability"
              description="FHIR-compliant data exchange with seamless integration capabilities"
              icon={Cloud}
            />
            <FeatureCard
              title="Security & Compliance"
              description="Enterprise-grade security with HIPAA and GDPR compliance"
              icon={Shield}
            />
          </div>

          <button className="group inline-flex items-center px-8 py-4 bg-[#5BDB94] text-[#0A1F44] rounded-lg font-semibold hover:bg-[#4cc584] transition-all">
            <span className="flex items-center">
              Explore Clinera Features
              <ArrowRight className="ml-2 w-5 h-5 group-hover:translate-x-1 transition-transform" />
            </span>
          </button>
        </div>

        <div className="relative">
          <TechStackVisual />
        </div>
      </div>
    </div>
  </section>
);

const LandingPage = () => (
  <div className="min-h-screen bg-[#0A1F44]">
    <Navbar />
    <Slider />
    <ClientLogos />
    <ClineraPlatform />
    <Footer />
    <style jsx global>{`
      @keyframes spin-slow {
        from { transform: rotate(0deg); }
        to { transform: rotate(360deg); }
      }
      @keyframes spin-reverse {
        from { transform: rotate(360deg); }
        to { transform: rotate(0deg); }
      }
      @keyframes pulse-custom {
        0%, 100% { transform: scale(1); opacity: 0.8; }
        50% { transform: scale(1.8); opacity: 0.3; }
      }
      @keyframes pulse-opacity {
        0%, 100% { opacity: 0.8; }
        50% { opacity: 0.2; }
      }
      @keyframes pulse-scale {
        0%, 100% { transform: scale(1); }
        50% { transform: scale(1.5); }
      }
      @keyframes dash {
        to {
          stroke-dashoffset: 100;
        }
      }
      @keyframes dash-slow {
        to {
          stroke-dashoffset: 20;
        }
      }
      .animate-spin-slow {
        animation: spin-slow 20s linear infinite;
      }
      .animate-spin-reverse {
        animation: spin-reverse 15s linear infinite;
      }
      .animate-pulse-custom {
        animation: pulse-custom 2s infinite;
      }
      .animate-pulse-opacity {
        animation: pulse-opacity 2s infinite;
      }
      .animate-pulse-scale {
        animation: pulse-scale 2s infinite;
      }
      .animate-dash {
        stroke-dasharray: 20;
        animation: dash 1.5s linear infinite;
      }
      .animate-dash-slow {
        stroke-dasharray: 10;
        animation: dash-slow 3s linear infinite;
      }
      @keyframes flow-path {
        0% { opacity: 0; }
        50% { opacity: 1; }
        100% { opacity: 0; }
      }
      .animate-flow-path {
        animation: flow-path 3s infinite;
      }
    `}</style>
  </div>
);

export default LandingPage;