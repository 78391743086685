import React from 'react';
import { Mail, Phone, MapPin, Linkedin, Twitter, Facebook } from 'lucide-react';
import { Link } from 'react-router-dom';

const Footer = () => (
  <footer className="bg-[#061631] border-t border-white/10">
    <div className="container mx-auto px-6 py-16">
      {/* Main Footer Content */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-12">
        {/* Company Info */}
        <div>
          <h3 className="text-2xl font-bold text-white mb-6">EVEREST MINDS</h3>
          <p className="text-white/70 mb-6">
            Transforming healthcare through innovative digital solutions and AI-powered platforms.
          </p>
          <div className="flex space-x-4">
            <a href="https://www.linkedin.com/company/everest-minds"
              target="_blank"
              rel="noopener noreferrer"
              className="text-white/60 hover:text-[#5BDB94] transition-colors">
              <Linkedin className="w-5 h-5" />
            </a>
            <a href="https://x.com/everestminds_eg"
              target="_blank"
              rel="noopener noreferrer"
              className="text-white/60 hover:text-[#5BDB94] transition-colors">
              <Twitter className="w-5 h-5" />
            </a>
            <a href="https://www.facebook.com/everestminds/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-white/60 hover:text-[#5BDB94] transition-colors">
              <Facebook className="w-5 h-5" />
            </a>
          </div>
        </div>

        {/* Quick Links */}
        <div>
          <h4 className="text-white font-semibold mb-6">Quick Links</h4>
          <ul className="space-y-4">
            <li>
              <Link to="/" className="text-white/70 hover:text-[#5BDB94] transition-colors">
                Home
              </Link>
            </li>
            <li>
              <Link to="/about" className="text-white/70 hover:text-[#5BDB94] transition-colors">
                About Us
              </Link>
            </li>
            <li>
              <Link to="/clinera" className="text-white/70 hover:text-[#5BDB94] transition-colors">
                Our Solutions
              </Link>
            </li>
            <li>
              <Link to="/success-stories" className="text-white/70 hover:text-[#5BDB94] transition-colors">
                Case Studies
              </Link>
            </li>
            <li>
              <Link to="/resources" className="text-white/70 hover:text-[#5BDB94] transition-colors">
                Resources
              </Link>
            </li>
            <li>
              <Link to="/blog" className="text-white/70 hover:text-[#5BDB94] transition-colors">
                Blog
              </Link>
            </li>
            <li>
              <Link to="/careers" className="text-white/70 hover:text-[#5BDB94] transition-colors">
                Careers
              </Link>
            </li>
          </ul>
        </div>

        {/* Solutions */}
        <div>
          <h4 className="text-white font-semibold mb-6">Our Solutions</h4>
          <ul className="space-y-4">
            <li>
              <Link to="/clinera" className="text-white/70 hover:text-[#5BDB94] transition-colors">
                Clinera Platform
              </Link>
            </li>
            <li>
              <Link to="/solutions/patient-registry" className="text-white/70 hover:text-[#5BDB94] transition-colors">
                Patient Registry
              </Link>
            </li>
            <li>
              <Link to="/solutions/healthcare-ai" className="text-white/70 hover:text-[#5BDB94] transition-colors">
                Healthcare AI
              </Link>
            </li>
            <li>
              <Link to="/solutions/data-analytics" className="text-white/70 hover:text-[#5BDB94] transition-colors">
                Data Analytics
              </Link>
            </li>
            <li>
              <Link to="/solutions/fhir-integration" className="text-white/70 hover:text-[#5BDB94] transition-colors">
                FHIR Integration
              </Link>
            </li>
            <li>
              <Link to="/solutions/custom-solutions" className="text-white/70 hover:text-[#5BDB94] transition-colors">
                Custom Solutions
              </Link>
            </li>
          </ul>
        </div>

        {/* Contact Info */}
        <div>
          <h4 className="text-white font-semibold mb-6">Contact Us</h4>
          <ul className="space-y-4">
            <li className="flex items-center text-white/70">
              <Mail className="w-5 h-5 mr-3 text-[#5BDB94]" />
              <a href="mailto:contact@everestminds.com" className="hover:text-[#5BDB94] transition-colors">
                info@everestminds.com
              </a>
            </li>
            <li className="flex items-center text-white/70">
              <Phone className="w-5 h-5 mr-3 text-[#5BDB94]" />
              <a href="tel:+1234567890" className="hover:text-[#5BDB94] transition-colors">
                +2 (2811) 80-53
              </a>
            </li>
            <li className="flex items-start text-white/70">
              <MapPin className="w-5 h-5 mr-3 text-[#5BDB94] mt-1" />
              <a href="https://goo.gl/maps/oyzAtAxCRhmcrLsF7"
                target="_blank"
                rel="noopener noreferrer"
                className="hover:text-[#5BDB94] transition-colors">
                607 South tower, Cairo Business Plaza,<br />
                N 90 St, New Cairo,<br />
                Cairo
              </a>
            </li>
          </ul>
        </div>
      </div>

      {/* Bottom Bar */}
      <div className="mt-16 pt-8 border-t border-white/10">
        <div className="flex flex-col md:flex-row justify-between items-center">
          <p className="text-white/60 text-sm mb-4 md:mb-0">
            © {new Date().getFullYear()} Everest Minds. All rights reserved.
          </p>
          <div className="flex space-x-6">
            <Link to="/privacy-policy" className="text-white/60 hover:text-[#5BDB94] text-sm transition-colors">
              Privacy Policy
            </Link>
            <Link to="/terms-of-service" className="text-white/60 hover:text-[#5BDB94] text-sm transition-colors">
              Terms of Service
            </Link>
            <Link to="/cookie-policy" className="text-white/60 hover:text-[#5BDB94] text-sm transition-colors">
              Cookie Policy
            </Link>
          </div>
        </div>
      </div>
    </div>
  </footer>
);

export default Footer; 