import React from 'react';
import { Network, Database, Link2, Workflow, Share2, Lock, Code, GitBranch } from 'lucide-react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

const InteroperabilityAnimation = () => (
  <div className="relative w-full h-[600px]">
    <svg width="100%" height="100%" viewBox="0 0 700 700">
      {/* Central FHIR Hub */}
      <circle
        cx="350"
        cy="350"
        r="60"
        fill="#0A1F44"
        stroke="#5BDB94"
        strokeWidth="2"
        className="animate-pulse-scale"
      />
      <text x="350" y="350" textAnchor="middle" fill="#5BDB94" fontSize="14">
        FHIR Hub
      </text>

      {/* Connecting Systems */}
      {[0, 60, 120, 180, 240, 300].map((angle, index) => {
        const radius = 150;
        const x = 350 + radius * Math.cos((angle * Math.PI) / 180);
        const y = 350 + radius * Math.sin((angle * Math.PI) / 180);

        return (
          <g key={index}>
            {/* Connection Lines */}
            <path
              d={`M350,350 L${x},${y}`}
              stroke="#5BDB94"
              strokeWidth="2"
              strokeDasharray="4,4"
              className="animate-dash-slow"
            />

            {/* System Nodes */}
            <circle
              cx={x}
              cy={y}
              r="30"
              fill="#0A1F44"
              stroke="#5BDB94"
              strokeWidth="2"
            />

            {/* Data Flow */}
            <circle
              r="4"
              fill="#5BDB94"
              className="animate-flow-path"
            >
              <animateMotion
                dur="3s"
                repeatCount="indefinite"
                path={`M350,350 L${x},${y}`}
              />
            </circle>
          </g>
        );
      })}
    </svg>
  </div>
);

const FeatureCard = ({ title, description, icon: Icon }) => (
  <div className="p-6 bg-white rounded-lg shadow-lg hover:shadow-xl transition-shadow">
    <div className="w-12 h-12 rounded-lg bg-[#5BDB94]/10 flex items-center justify-center mb-4">
      <Icon className="w-6 h-6 text-[#5BDB94]" />
    </div>
    <h3 className="text-xl font-semibold text-[#0A1F44] mb-3">{title}</h3>
    <p className="text-gray-600">{description}</p>
  </div>
);

const ResourceCard = ({ name, description }) => (
  <div className="p-6 border border-[#5BDB94]/30 rounded-lg hover:border-[#5BDB94] transition-colors">
    <div className="flex items-center mb-3">
      <Code className="w-5 h-5 text-[#5BDB94] mr-2" />
      <h4 className="text-lg font-semibold text-[#0A1F44]">{name}</h4>
    </div>
    <p className="text-gray-600 text-sm">{description}</p>
  </div>
);

const FHIRIntegration = () => {
  return (
    <div className="min-h-screen bg-[#F8FAFC]">
      <Navbar />

      {/* Hero Section */}
      <section className="relative bg-[#0A1F44] py-20">
        <div className="container mx-auto px-6">
          <div className="grid md:grid-cols-2 gap-12 items-center">
            <div>
              <div className="mb-6 inline-block py-2 px-4 bg-[#5BDB94]/20 rounded-full">
                <span className="text-[#5BDB94] font-semibold">FHIR INTEGRATION</span>
              </div>
              <h1 className="text-4xl md:text-5xl font-bold text-white mb-6">
                Seamless Healthcare Interoperability
              </h1>
              <p className="text-xl text-white/80 mb-8">
                Connect your healthcare systems with our FHIR-compliant integration platform. Enable secure, standardized data exchange across your entire healthcare ecosystem.
              </p>
              <button className="px-8 py-4 bg-[#5BDB94] text-[#0A1F44] rounded-lg font-semibold hover:bg-[#4cc584] transition-all">
                Explore FHIR Solutions
              </button>
            </div>
            <div className="relative">
              <InteroperabilityAnimation />
            </div>
          </div>
        </div>
      </section>

      {/* Features Section */}
      <section className="py-20">
        <div className="container mx-auto px-6">
          <div className="text-center mb-16">
            <h2 className="text-3xl font-bold text-[#0A1F44] mb-4">
              Complete FHIR Integration Platform
            </h2>
            <p className="text-xl text-gray-600 max-w-2xl mx-auto">
              Everything you need to implement FHIR standards and achieve true healthcare interoperability
            </p>
          </div>

          <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8">
            <FeatureCard
              title="FHIR API Gateway"
              description="Secure REST API endpoints supporting all FHIR resources and operations"
              icon={Network}
            />
            <FeatureCard
              title="Data Mapping"
              description="Automated mapping between legacy systems and FHIR resources"
              icon={Database}
            />
            <FeatureCard
              title="Smart Integration"
              description="Pre-built connectors for major EHR systems and healthcare platforms"
              icon={Link2}
            />
            <FeatureCard
              title="Workflow Engine"
              description="Customizable clinical workflows with FHIR-based orchestration"
              icon={Workflow}
            />
            <FeatureCard
              title="Data Exchange"
              description="Support for RESTful API, messaging, and bulk data transfer"
              icon={Share2}
            />
            <FeatureCard
              title="Security Suite"
              description="OAuth 2.0, SMART on FHIR, and role-based access control"
              icon={Lock}
            />
            <FeatureCard
              title="Version Control"
              description="Support for multiple FHIR versions and backward compatibility"
              icon={GitBranch}
            />
            <FeatureCard
              title="API Management"
              description="Complete API lifecycle management and monitoring tools"
              icon={Code}
            />
          </div>
        </div>
      </section>

      {/* FHIR Resources Section */}
      <section className="py-20 bg-white">
        <div className="container mx-auto px-6">
          <div className="text-center mb-16">
            <h2 className="text-3xl font-bold text-[#0A1F44] mb-4">
              Supported FHIR Resources
            </h2>
            <p className="text-xl text-gray-600 max-w-2xl mx-auto">
              Comprehensive support for core FHIR resources and extensions
            </p>
          </div>

          <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-6">
            <ResourceCard
              name="Patient"
              description="Demographics and administrative information about an individual"
            />
            <ResourceCard
              name="Observation"
              description="Measurements and simple assertions about a patient"
            />
            <ResourceCard
              name="Condition"
              description="Clinical conditions, problems, or diagnoses"
            />
            <ResourceCard
              name="Medication"
              description="Definition of a medication and its properties"
            />
            <ResourceCard
              name="Encounter"
              description="Healthcare encounters and visits"
            />
            <ResourceCard
              name="Procedure"
              description="Actions taken for or with a patient"
            />
          </div>
        </div>
      </section>

      {/* Stats Section */}
      <section className="bg-[#0A1F44] py-20">
        <div className="container mx-auto px-6">
          <div className="grid md:grid-cols-4 gap-8 text-center">
            <div className="p-6">
              <div className="text-4xl font-bold text-[#5BDB94] mb-2">100+</div>
              <div className="text-white/80">FHIR Resources</div>
            </div>
            <div className="p-6">
              <div className="text-4xl font-bold text-[#5BDB94] mb-2">99.9%</div>
              <div className="text-white/80">Uptime SLA</div>
            </div>
            <div className="p-6">
              <div className="text-4xl font-bold text-[#5BDB94] mb-2">10M+</div>
              <div className="text-white/80">API Calls Daily</div>
            </div>
            <div className="p-6">
              <div className="text-4xl font-bold text-[#5BDB94] mb-2">50+</div>
              <div className="text-white/80">EHR Integrations</div>
            </div>
          </div>
        </div>
      </section>

      {/* CTA Section */}
      <section className="py-20">
        <div className="container mx-auto px-6">
          <div className="bg-[#0A1F44] rounded-2xl p-12 text-center">
            <h2 className="text-3xl font-bold text-white mb-6">
              Ready to Enable FHIR Integration?
            </h2>
            <p className="text-xl text-white/80 mb-8 max-w-2xl mx-auto">
              Join healthcare organizations that have achieved seamless interoperability with our FHIR integration platform.
            </p>
            <button className="px-8 py-4 bg-[#5BDB94] text-[#0A1F44] rounded-lg font-semibold hover:bg-[#4cc584] transition-all">
              Start Integration
            </button>
          </div>
        </div>
      </section>

      <style jsx global>{`
        @keyframes pulse-opacity {
          0%, 100% { opacity: 0.8; }
          50% { opacity: 0.2; }
        }
        @keyframes pulse-scale {
          0%, 100% { transform: scale(1); }
          50% { transform: scale(1.5); }
        }
        @keyframes dash-slow {
          to {
            stroke-dashoffset: 20;
          }
        }
        .animate-pulse-opacity {
          animation: pulse-opacity 2s infinite;
        }
        .animate-pulse-scale {
          animation: pulse-scale 2s infinite;
        }
        .animate-dash-slow {
          stroke-dasharray: 10;
          animation: dash-slow 3s linear infinite;
        }
        @keyframes flow-path {
          0% { opacity: 0; }
          50% { opacity: 1; }
          100% { opacity: 0; }
        }
        .animate-flow-path {
          animation: flow-path 3s infinite;
        }
      `}</style>

      <Footer />
    </div>
  );
};

export default FHIRIntegration; 