import React, { useState, useEffect } from 'react';
import {
  Activity,
  Users,
  FileText,
  Brain,
  Dna,
  Microscope,
  HeartPulse,
  ClipboardList,
  Shield,
  Workflow,
  Video,
  BarChart,
  ArrowRight,
  CheckCircle,
  Globe
} from 'lucide-react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

const AnimatedNumber = ({ value, duration = 2000 }) => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    let startTime;
    const startValue = 0;
    const endValue = value;

    const animation = (currentTime) => {
      if (!startTime) startTime = currentTime;
      const progress = Math.min((currentTime - startTime) / duration, 1);

      setCount(Math.floor(progress * (endValue - startValue) + startValue));

      if (progress < 1) {
        requestAnimationFrame(animation);
      }
    };

    requestAnimationFrame(animation);
  }, [value, duration]);

  return <span>{count}+</span>;
};

const FeatureCard = ({ icon: Icon, title, description }) => (
  <div className="p-8 bg-white/5 rounded-xl border border-white/10 hover:border-[#00B8A9]/30 transition-all group hover:transform hover:scale-105 hover:-translate-y-2 duration-300">
    <div className="w-16 h-16 rounded-xl bg-[#00B8A9]/10 flex items-center justify-center mb-6 group-hover:bg-[#00B8A9]/20 transition-colors transform group-hover:rotate-6 duration-300">
      <Icon className="w-8 h-8 text-[#00B8A9]" />
    </div>
    <h3 className="text-2xl font-semibold text-white mb-4">{title}</h3>
    <p className="text-white/70 leading-relaxed">{description}</p>
  </div>
);

const ModuleSection = ({ title, description, features, isAlternate = false, imageUrl }) => (
  <div className={`py-24 ${isAlternate ? 'bg-gradient-to-b from-[#00B8A9]/5 to-transparent' : ''}`}>
    <div className="container mx-auto px-6">
      <div className={`flex flex-col ${isAlternate ? 'md:flex-row-reverse' : 'md:flex-row'} items-center gap-16 mb-16`}>
        <div className="md:w-1/2">
          <h2 className="text-4xl font-bold text-white mb-6">{title}</h2>
          <p className="text-xl text-white/70 leading-relaxed">
            {description}
          </p>
        </div>
        <div className="md:w-1/2 relative">
          <div className="absolute inset-0 bg-gradient-to-r from-[#00B8A9] to-[#5BDB94] rounded-full blur-[100px] opacity-20"></div>
          <img
            src={imageUrl}
            alt={title}
            className="rounded-2xl w-full h-[400px] object-cover shadow-xl transform hover:scale-105 transition-all duration-500"
          />
        </div>
      </div>
      <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
        {features.map((feature, index) => (
          <FeatureCard key={index} {...feature} />
        ))}
      </div>
    </div>
  </div>
);

const StatsSection = () => (
  <div className="py-20 relative overflow-hidden">
    <div className="absolute inset-0 bg-gradient-to-r from-[#00B8A9]/20 to-[#5BDB94]/20"></div>
    <div className="container mx-auto px-6">
      <div className="grid grid-cols-2 md:grid-cols-4 gap-8">
        {[
          { value: 100, label: "Healthcare Providers" },
          { value: 50000, label: "Patients Served" },
          { value: 95, label: "Satisfaction Rate" },
          { value: 30, label: "Countries" }
        ].map((stat, index) => (
          <div key={index} className="text-center transform hover:scale-105 transition-transform duration-300">
            <div className="text-4xl md:text-5xl font-bold text-[#00B8A9] mb-2">
              <AnimatedNumber value={stat.value} />
            </div>
            <div className="text-white/70">{stat.label}</div>
          </div>
        ))}
      </div>
    </div>
  </div>
);

const BenefitCard = ({ icon: Icon, title }) => (
  <div className="flex items-center gap-4 p-4 bg-white/5 rounded-lg hover:bg-white/10 transition-all cursor-pointer group">
    <Icon className="w-6 h-6 text-[#00B8A9] group-hover:scale-110 transition-transform" />
    <span className="text-white/90">{title}</span>
  </div>
);

const ClineraPlatform = () => {
  const modules = {
    emr: {
      title: "Specialized EMR",
      description: "A comprehensive electronic medical record system designed specifically for complex therapeutic areas including rare diseases, genetic disorders, oncology, and chronic conditions.",
      imageUrl: "https://vbrwqrvpeehhjyblvicj.supabase.co/storage/v1/object/public/media/audit-example.png",
      features: [
        {
          icon: Dna,
          title: "Genetic Disease Management",
          description: "Specialized workflows and data capture for genetic disorders, including family history mapping and genetic test tracking."
        },
        {
          icon: Microscope,
          title: "Oncology Care",
          description: "Comprehensive cancer care management with staging, treatment planning, and response monitoring."
        },
        {
          icon: HeartPulse,
          title: "Chronic Disease Tracking",
          description: "Long-term condition monitoring with trend analysis and intervention tracking."
        }
      ]
    },
    registry: {
      title: "Patient Registry",
      description: "Advanced patient registry system for collecting, analyzing, and reporting real-world evidence across various therapeutic areas.",
      imageUrl: "https://images.unsplash.com/photo-1581594693702-fbdc51b2763b?auto=format&fit=crop&w=1200",
      features: [
        {
          icon: FileText,
          title: "Data Collection",
          description: "Customizable forms and workflows for systematic data collection across multiple sites and conditions."
        },
        {
          icon: Brain,
          title: "AI-Powered Analytics",
          description: "Advanced analytics and machine learning for pattern recognition and outcome prediction."
        },
        {
          icon: BarChart,
          title: "Real-World Evidence",
          description: "Comprehensive reporting and analysis tools for generating real-world evidence and insights."
        }
      ]
    },
    qms: {
      title: "Quality Management System",
      description: "Integrated quality management system ensuring compliance, patient safety, and operational excellence.",
      imageUrl: "https://vbrwqrvpeehhjyblvicj.supabase.co/storage/v1/object/public/media/audit-example.png",
      features: [
        {
          icon: Shield,
          title: "Compliance Management",
          description: "Automated compliance tracking and reporting for various healthcare standards and regulations."
        },
        {
          icon: ClipboardList,
          title: "Audit Management",
          description: "Streamlined audit processes with automated scheduling, tracking, and reporting."
        },
        {
          icon: Workflow,
          title: "Process Automation",
          description: "Automated workflows for quality processes, document control, and corrective actions."
        }
      ]
    },
    mdt: {
      title: "Virtual MDT Platform",
      description: "Advanced virtual multidisciplinary team platform enabling seamless collaboration and decision-making across healthcare providers.",
      imageUrl: "https://images.unsplash.com/photo-1557426272-fc759fdf7a8d?auto=format&fit=crop&w=1200",
      features: [
        {
          icon: Video,
          title: "Virtual Collaboration",
          description: "Secure video conferencing with integrated clinical data sharing and annotation tools."
        },
        {
          icon: Users,
          title: "Team Management",
          description: "Comprehensive team coordination with role-based access and task management."
        },
        {
          icon: Activity,
          title: "Decision Support",
          description: "AI-assisted decision support tools and structured discussion frameworks."
        }
      ]
    }
  };

  return (
    <div className="min-h-screen bg-[#0A1F44]">
      <Navbar />

      {/* Hero Section */}
      <section className="pt-32 pb-20 relative overflow-hidden">
        <div className="absolute inset-0">
          <div className="absolute top-20 right-0 w-[800px] h-[800px] bg-gradient-to-r from-[#00B8A9] to-[#5BDB94] rounded-full blur-[120px] opacity-10 animate-pulse-slow"></div>
        </div>
        <div className="container mx-auto px-6 relative">
          <div className="grid md:grid-cols-2 gap-16 items-center">
            <div>
              <div className="mb-6 inline-block py-2 px-4 bg-gradient-to-r from-[#00B8A9]/20 to-[#5BDB94]/10 rounded-full">
                <span className="text-[#00B8A9] font-semibold tracking-wider">CLINERA PLATFORM</span>
              </div>
              <h1 className="text-5xl md:text-6xl font-bold text-white mb-8 leading-tight">
                Next-Generation <br />
                <span className="text-transparent bg-clip-text bg-gradient-to-r from-[#00B8A9] to-[#5BDB94]">
                  Healthcare Platform
                </span>
              </h1>
              <p className="text-xl text-white/70 mb-8 leading-relaxed">
                A comprehensive digital health platform designed for specialized therapeutic areas,
                combining EMR, patient registry, quality management, and virtual MDT capabilities.
              </p>
              <div className="flex gap-4">
                <button className="group px-8 py-4 bg-[#00B8A9] text-white rounded-lg font-semibold hover:bg-[#00a598] transition-all flex items-center gap-2">
                  Request Demo
                  <ArrowRight className="w-5 h-5 group-hover:translate-x-1 transition-transform" />
                </button>
                <button className="px-8 py-4 border-2 border-[#5BDB94] text-[#5BDB94] rounded-lg font-semibold hover:bg-[#5BDB94]/10 transition-all">
                  Learn More
                </button>
              </div>
            </div>
            <div className="relative">
              <img
                src="https://vbrwqrvpeehhjyblvicj.supabase.co/storage/v1/object/public/media/audit-example.png"
                alt="Healthcare Platform"
                className="rounded-2xl shadow-2xl transform hover:scale-105 transition-all duration-500"
              />
            </div>
          </div>
        </div>
      </section>

      <StatsSection />

      {/* Module Sections */}
      <ModuleSection {...modules.emr} isAlternate={true} />
      <ModuleSection {...modules.registry} />
      <ModuleSection {...modules.qms} isAlternate={true} />
      <ModuleSection {...modules.mdt} />

      {/* CTA Section */}
      <section className="py-24 relative overflow-hidden">
        <div className="absolute inset-0">
          <div className="absolute inset-0 bg-gradient-to-r from-[#00B8A9]/20 via-[#5BDB94]/10 to-[#00B8A9]/20"></div>
          <img
            src="https://images.unsplash.com/photo-1576670759302-0c02c40a0dc3?auto=format&fit=crop&w=2000"
            alt="Healthcare Background"
            className="w-full h-full object-cover opacity-10"
          />
        </div>
        <div className="container mx-auto px-6 relative">
          <div className="max-w-4xl mx-auto text-center">
            <h2 className="text-4xl md:text-5xl font-bold text-white mb-8">
              Transform Your Healthcare Practice
            </h2>
            <p className="text-xl text-white/70 mb-12 leading-relaxed">
              Join leading healthcare providers in delivering exceptional patient care with Clinera's
              comprehensive platform.
            </p>
            <div className="flex gap-6 justify-center">
              <button className="group px-8 py-4 bg-[#00B8A9] text-white rounded-lg font-semibold hover:bg-[#00a598] transition-all flex items-center gap-2">
                Schedule Demo
                <ArrowRight className="w-5 h-5 group-hover:translate-x-1 transition-transform" />
              </button>
              <button className="px-8 py-4 border-2 border-[#5BDB94] text-[#5BDB94] rounded-lg font-semibold hover:bg-[#5BDB94]/10 transition-all">
                Learn More
              </button>
            </div>
          </div>
        </div>
      </section>

      <Footer />

      <style jsx global>{`
        @keyframes pulse-slow {
          0%, 100% { opacity: 0.1; }
          50% { opacity: 0.2; }
        }
        .animate-pulse-slow {
          animation: pulse-slow 4s cubic-bezier(0.4, 0, 0.6, 1) infinite;
        }
      `}</style>
    </div>
  );
};

export default ClineraPlatform; 