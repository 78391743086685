import React from 'react';
import {
  Clock,
  Users,
  LineChart,
  Mail,
  Building,
  Lightbulb,
  ArrowRight,
  CheckCircle,
  MousePointer,
  Brain,
  RefreshCw,
  Handshake
} from 'lucide-react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

const TimelineEvent = ({ year, title, description, isLeft = true, image }) => (
  <div className={`relative ${isLeft ? 'md:ml-auto md:pl-12' : 'md:mr-auto md:pr-12'} md:w-1/2`}>
    <div className="absolute top-0 w-4 h-4 rounded-full bg-[#00B8A9] transform -translate-y-1/2 
      ${isLeft ? 'md:-left-2' : 'md:-right-2'} z-10"></div>
    <div className="bg-white/5 rounded-xl border border-white/10 overflow-hidden group hover:border-[#00B8A9]/30 transition-all">
      {image && (
        <div className="relative h-48 overflow-hidden">
          <img
            src={image}
            alt={title}
            className="w-full h-full object-cover transform group-hover:scale-110 transition-all duration-500"
          />
          <div className="absolute inset-0 bg-gradient-to-t from-[#0A1F44] to-transparent opacity-60"></div>
        </div>
      )}
      <div className="p-8">
        <div className="text-[#00B8A9] font-bold mb-2">{year}</div>
        <h3 className="text-2xl font-bold text-white mb-4">{title}</h3>
        <p className="text-white/70">{description}</p>
      </div>
    </div>
  </div>
);

const StatCard = ({ icon: Icon, number, label }) => (
  <div className="p-6 bg-white/5 rounded-lg border border-white/10 hover:scale-105 transition-all duration-300 group">
    <Icon className="w-8 h-8 text-[#00B8A9] mb-4 group-hover:scale-110 transition-transform" />
    <div className="text-4xl font-bold text-[#00B8A9] mb-2">{number}</div>
    <div className="text-white/70">{label}</div>
  </div>
);

const LearningCard = ({ icon: Icon, title, description }) => (
  <div className="p-8 bg-white/5 rounded-xl border border-white/10 hover:border-[#00B8A9]/30 transition-all group">
    <div className="w-16 h-16 rounded-xl bg-[#00B8A9]/10 flex items-center justify-center mb-6 group-hover:bg-[#00B8A9]/20 transition-colors">
      <Icon className="w-8 h-8 text-[#00B8A9]" />
    </div>
    <h3 className="text-xl font-semibold text-white mb-4">{title}</h3>
    <p className="text-white/70">{description}</p>
  </div>
);

const SuccessStory = () => {
  return (
    <div className="min-h-screen bg-[#0A1F44]">
      <Navbar />

      {/* Hero Section */}
      <section className="pt-32 pb-20 relative overflow-hidden">
        <div className="absolute inset-0">
          <div className="absolute top-20 right-0 w-[800px] h-[800px] bg-gradient-to-r from-[#00B8A9] to-[#5BDB94] rounded-full blur-[120px] opacity-10 animate-pulse-slow"></div>
        </div>
        <div className="container mx-auto px-6 relative">
          <div className="grid md:grid-cols-2 gap-16 items-center">
            <div className="max-w-4xl">
              <div className="mb-6 inline-block py-2 px-4 bg-gradient-to-r from-[#00B8A9]/20 to-[#5BDB94]/10 rounded-full">
                <span className="text-[#00B8A9] font-semibold tracking-wider">SUCCESS STORY</span>
              </div>
              <h1 className="text-4xl md:text-6xl font-bold text-white mb-8 leading-tight">
                The Story of iClaim: <br />
                <span className="text-transparent bg-clip-text bg-gradient-to-r from-[#00B8A9] to-[#5BDB94]">
                  Cutting Patient Wait Times through Design, Technology & Partnerships
                </span>
              </h1>
              <p className="text-xl text-white/70 mb-8 leading-relaxed">
                How we transformed cancer patient care in Egypt by reducing prescription approval times from 27 days to just 2 days.
              </p>
            </div>
            <div className="relative">
              <img
                src="https://images.unsplash.com/photo-1584982751601-97dcc096659c?auto=format&fit=crop&w=1200"
                alt="Healthcare Transformation"
                className="rounded-2xl shadow-2xl transform hover:scale-105 transition-all duration-500"
              />
            </div>
          </div>
        </div>
      </section>

      {/* Stats Section */}
      <section className="py-20 relative overflow-hidden">
        <div className="absolute inset-0">
          <img
            src="https://images.unsplash.com/photo-1579684385127-1ef15d508118?auto=format&fit=crop&w=2000"
            alt="Healthcare Background"
            className="w-full h-full object-cover opacity-5"
          />
        </div>
        <div className="container mx-auto px-6 relative">
          <div className="grid md:grid-cols-4 gap-8">
            <StatCard
              icon={Clock}
              number="93%"
              label="Reduction in Wait Times"
            />
            <StatCard
              icon={Users}
              number="1000s"
              label="Patients Impacted"
            />
            <StatCard
              icon={Building}
              number="27"
              label="Cities Covered"
            />
            <StatCard
              icon={LineChart}
              number="2"
              label="Days for Approval"
            />
          </div>
        </div>
      </section>

      {/* Timeline Section */}
      <section className="py-24 relative">
        <div className="absolute left-1/2 top-0 bottom-0 w-0.5 bg-white/10"></div>
        <div className="container mx-auto px-6">
          <div className="space-y-24">
            <TimelineEvent
              year="2018"
              title="The Beginning in Cairo"
              description="Healthcare in Egypt faced significant challenges with a paper-based prescription approval process that could take weeks."
              image="https://images.unsplash.com/photo-1603398938378-e54eab446dde?auto=format&fit=crop&w=1200"
            />
            <TimelineEvent
              year="2018"
              title="First Attempt & Learning"
              description="Initial pilot in Alexandria revealed crucial insights about user needs and system adoption challenges."
              isLeft={false}
              image="https://images.unsplash.com/photo-1576091160550-2173dba999ef?auto=format&fit=crop&w=1200"
            />
            <TimelineEvent
              year="2019"
              title="The Email Innovation"
              description="Reimagined the solution with email-based approvals, making it intuitive for busy healthcare professionals."
              image="https://images.unsplash.com/photo-1557426272-fc759fdf7a8d?auto=format&fit=crop&w=1200"
            />
            <TimelineEvent
              year="2024"
              title="Transformative Impact"
              description="Successfully reduced waiting times by 90%, transforming the lives of thousands of patients."
              isLeft={false}
              image="https://images.unsplash.com/photo-1551076805-e1869033e561?auto=format&fit=crop&w=1200"
            />
          </div>
        </div>
      </section>

      {/* Key Learnings Section */}
      <section className="py-24 bg-gradient-to-b from-[#00B8A9]/5 to-transparent relative overflow-hidden">
        <div className="absolute inset-0">
          <img
            src="https://images.unsplash.com/photo-1576670759302-0c02c40a0dc3?auto=format&fit=crop&w=2000"
            alt="Background Pattern"
            className="w-full h-full object-cover opacity-5"
          />
        </div>
        <div className="container mx-auto px-6 relative">
          <h2 className="text-3xl font-bold text-white mb-12 text-center">Key Takeaways</h2>
          <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8">
            <LearningCard
              icon={MousePointer}
              title="Listen and Adapt"
              description="Success came from listening to and adapting solutions based on real user needs."
            />
            <LearningCard
              icon={Brain}
              title="Simplify, Don't Complicate"
              description="True innovation often lies in simplification rather than complicating existing processes."
            />
            <LearningCard
              icon={RefreshCw}
              title="Endurance in Transformation"
              description="Persistence through setbacks was crucial, with each failure providing valuable insights."
            />
            <LearningCard
              icon={Handshake}
              title="Power of Partnerships"
              description="Public-private partnerships were key to achieving scalable and sustainable impacts."
            />
          </div>
        </div>
      </section>

      {/* CTA Section */}
      <section className="py-24 relative overflow-hidden">
        <div className="absolute inset-0">
          <div className="absolute inset-0 bg-gradient-to-r from-[#00B8A9]/20 via-[#5BDB94]/10 to-[#00B8A9]/20"></div>
          <img
            src="https://images.unsplash.com/photo-1576091160399-112ba8d25d1d?auto=format&fit=crop&w=2000"
            alt="Healthcare Innovation"
            className="w-full h-full object-cover opacity-10"
          />
        </div>
        <div className="container mx-auto px-6 relative">
          <div className="max-w-4xl mx-auto text-center">
            <h2 className="text-4xl font-bold text-white mb-8">
              Ready to Transform Your Healthcare Process?
            </h2>
            <p className="text-xl text-white/70 mb-12 leading-relaxed">
              Learn how we can help you achieve similar results with our innovative solutions.
            </p>
            <div className="flex gap-6 justify-center">
              <button className="group px-8 py-4 bg-[#00B8A9] text-white rounded-lg font-semibold hover:bg-[#00a598] transition-all flex items-center gap-2">
                Contact Us
                <ArrowRight className="w-5 h-5 group-hover:translate-x-1 transition-transform" />
              </button>
              <button className="px-8 py-4 border-2 border-[#5BDB94] text-[#5BDB94] rounded-lg font-semibold hover:bg-[#5BDB94]/10 transition-all">
                View More Stories
              </button>
            </div>
          </div>
        </div>
      </section>

      <Footer />

      <style jsx global>{`
        @keyframes pulse-slow {
          0%, 100% { opacity: 0.1; }
          50% { opacity: 0.2; }
        }
        .animate-pulse-slow {
          animation: pulse-slow 4s cubic-bezier(0.4, 0, 0.6, 1) infinite;
        }
      `}</style>
    </div>
  );
};

export default SuccessStory; 