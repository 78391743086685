import React from 'react';
import { Brain, Microscope, HeartPulse, Stethoscope, Bot, BarChart, Shield, Users } from 'lucide-react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

const AIProcessingAnimation = () => (
  <div className="relative w-full h-[600px]">
    <svg width="100%" height="100%" viewBox="0 0 700 700">
      {/* AI Processing Flow */}
      <g className="animate-pulse-opacity">
        {/* Data Input Streams */}
        <path
          d="M100,350 C200,350 250,200 350,200"
          fill="none"
          stroke="#5BDB94"
          strokeWidth="2"
          strokeDasharray="4,4"
          className="animate-dash-slow"
        />
        <path
          d="M600,350 C500,350 450,200 350,200"
          fill="none"
          stroke="#5BDB94"
          strokeWidth="2"
          strokeDasharray="4,4"
          className="animate-dash-slow"
        />

        {/* AI Processing Center */}
        <circle
          cx="350"
          cy="350"
          r="60"
          fill="#0A1F44"
          stroke="#5BDB94"
          strokeWidth="2"
          className="animate-pulse-scale"
        />

        {/* Output Streams */}
        <path
          d="M350,500 C450,500 500,350 600,350"
          fill="none"
          stroke="#5BDB94"
          strokeWidth="2"
          strokeDasharray="4,4"
          className="animate-dash-slow"
        />
        <path
          d="M350,500 C250,500 200,350 100,350"
          fill="none"
          stroke="#5BDB94"
          strokeWidth="2"
          strokeDasharray="4,4"
          className="animate-dash-slow"
        />

        {/* Animated Data Points */}
        {[...Array(6)].map((_, i) => (
          <circle
            key={i}
            r="4"
            fill="#5BDB94"
            className="animate-flow-path"
            style={{
              animationDelay: `${i * 0.5}s`
            }}
          >
            <animateMotion
              dur="3s"
              repeatCount="indefinite"
              path="M100,350 C200,350 250,200 350,200"
            />
          </circle>
        ))}
      </g>

      {/* AI Core Text */}
      <text x="350" y="350" textAnchor="middle" fill="#5BDB94" fontSize="14">
        AI Core
      </text>
    </svg>
  </div>
);

const FeatureCard = ({ title, description, icon: Icon }) => (
  <div className="p-6 bg-white rounded-lg shadow-lg hover:shadow-xl transition-shadow">
    <div className="w-12 h-12 rounded-lg bg-[#5BDB94]/10 flex items-center justify-center mb-4">
      <Icon className="w-6 h-6 text-[#5BDB94]" />
    </div>
    <h3 className="text-xl font-semibold text-[#0A1F44] mb-3">{title}</h3>
    <p className="text-gray-600">{description}</p>
  </div>
);

const HealthcareAI = () => {
  return (
    <div className="min-h-screen bg-[#F8FAFC]">
      <Navbar />

      {/* Hero Section */}
      <section className="relative bg-[#0A1F44] py-20">
        <div className="container mx-auto px-6">
          <div className="grid md:grid-cols-2 gap-12 items-center">
            <div>
              <div className="mb-6 inline-block py-2 px-4 bg-[#5BDB94]/20 rounded-full">
                <span className="text-[#5BDB94] font-semibold">AI MEDICAL ASSISTANT</span>
              </div>
              <h1 className="text-4xl md:text-5xl font-bold text-white mb-6">
                Your Intelligent Medical Voice Assistant
              </h1>
              <p className="text-xl text-white/80 mb-8">
                Meet your AI-powered medical assistant that understands voice commands, automates clinical documentation, and streamlines your daily practice workflow.
              </p>
              <button className="px-8 py-4 bg-[#5BDB94] text-[#0A1F44] rounded-lg font-semibold hover:bg-[#4cc584] transition-all">
                Schedule a Demo
              </button>
            </div>
            <div className="relative">
              <AIProcessingAnimation />
            </div>
          </div>
        </div>
      </section>

      {/* Features Section */}
      <section className="py-20">
        <div className="container mx-auto px-6">
          <div className="text-center mb-16">
            <h2 className="text-3xl font-bold text-[#0A1F44] mb-4">
              Empowering Doctors with Voice-Enabled AI
            </h2>
            <p className="text-xl text-gray-600 max-w-2xl mx-auto">
              Focus on your patients while our AI assistant handles documentation, scheduling, and clinical support tasks
            </p>
          </div>

          <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8">
            <FeatureCard
              title="Voice Documentation"
              description="Natural voice commands for real-time clinical documentation and EHR updates during patient visits"
              icon={Brain}
            />
            <FeatureCard
              title="Smart Scheduling"
              description="Voice-controlled appointment management and intelligent patient prioritization"
              icon={Bot}
            />
            <FeatureCard
              title="Clinical Support"
              description="Real-time clinical guidelines and drug interaction checks through voice queries"
              icon={Microscope}
            />
            <FeatureCard
              title="Patient Summary"
              description="Instant voice-activated access to patient history and vital trends"
              icon={HeartPulse}
            />
            <FeatureCard
              title="Voice Orders"
              description="Quick voice commands for lab orders, prescriptions, and referrals"
              icon={Stethoscope}
            />
            <FeatureCard
              title="Practice Insights"
              description="Voice-accessible analytics on practice performance and patient outcomes"
              icon={BarChart}
            />
            <FeatureCard
              title="Secure Platform"
              description="HIPAA-compliant voice recognition with end-to-end encryption"
              icon={Shield}
            />
            <FeatureCard
              title="Team Collaboration"
              description="Voice messaging and task delegation across your healthcare team"
              icon={Users}
            />
          </div>
        </div>
      </section>

      {/* Stats Section */}
      <section className="bg-[#0A1F44] py-20">
        <div className="container mx-auto px-6">
          <div className="grid md:grid-cols-4 gap-8 text-center">
            <div className="p-6">
              <div className="text-4xl font-bold text-[#5BDB94] mb-2">70%</div>
              <div className="text-white/80">Documentation Time Saved</div>
            </div>
            <div className="p-6">
              <div className="text-4xl font-bold text-[#5BDB94] mb-2">95%</div>
              <div className="text-white/80">Voice Recognition Accuracy</div>
            </div>
            <div className="p-6">
              <div className="text-4xl font-bold text-[#5BDB94] mb-2">24/7</div>
              <div className="text-white/80">Assistant Availability</div>
            </div>
            <div className="p-6">
              <div className="text-4xl font-bold text-[#5BDB94] mb-2">2hr+</div>
              <div className="text-white/80">Saved Daily per Doctor</div>
            </div>
          </div>
        </div>
      </section>

      {/* CTA Section */}
      <section className="py-20">
        <div className="container mx-auto px-6">
          <div className="bg-[#0A1F44] rounded-2xl p-12 text-center">
            <h2 className="text-3xl font-bold text-white mb-6">
              Ready to Have Your Own Medical AI Assistant?
            </h2>
            <p className="text-xl text-white/80 mb-8 max-w-2xl mx-auto">
              Join thousands of doctors who have transformed their practice with our voice-enabled AI assistant. Schedule a demo to see how we can save you hours of documentation time daily.
            </p>
            <button className="px-8 py-4 bg-[#5BDB94] text-[#0A1F44] rounded-lg font-semibold hover:bg-[#4cc584] transition-all">
              Get Started Now
            </button>
          </div>
        </div>
      </section>

      <style jsx global>{`
        @keyframes pulse-opacity {
          0%, 100% { opacity: 0.8; }
          50% { opacity: 0.2; }
        }
        @keyframes pulse-scale {
          0%, 100% { transform: scale(1); }
          50% { transform: scale(1.5); }
        }
        @keyframes dash-slow {
          to {
            stroke-dashoffset: 20;
          }
        }
        .animate-pulse-opacity {
          animation: pulse-opacity 2s infinite;
        }
        .animate-pulse-scale {
          animation: pulse-scale 2s infinite;
        }
        .animate-dash-slow {
          stroke-dasharray: 10;
          animation: dash-slow 3s linear infinite;
        }
        @keyframes flow-path {
          0% { opacity: 0; }
          50% { opacity: 1; }
          100% { opacity: 0; }
        }
        .animate-flow-path {
          animation: flow-path 3s infinite;
        }
      `}</style>

      <Footer />
    </div>
  );
};

export default HealthcareAI; 