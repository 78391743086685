import React from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

const DataAnalytics = () => {
  return (
    <div className="min-h-screen bg-[#F8FAFC]">
      <Navbar />
      <main className="container mx-auto px-6 py-16">
        <h1 className="text-4xl font-bold text-[#061631] mb-8">Healthcare Data Analytics</h1>
        <p className="text-lg text-gray-600">
          Transform your healthcare data into actionable insights with our advanced analytics solutions.
        </p>
        {/* Add more content here */}
      </main>
      <Footer />
    </div>
  );
};

export default DataAnalytics; 