import React from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

const PrivacyPolicy = () => {
  return (
    <div className="min-h-screen bg-[#0A1F44]">
      <Navbar />
      <div className="container mx-auto px-6 py-24">
        <div className="max-w-4xl mx-auto">
          <h1 className="text-5xl font-bold text-white mb-6">Privacy Policy</h1>
          <p className="text-white/80 mb-12 text-lg">Last updated: {new Date().toLocaleDateString()}</p>

          <div className="space-y-16">
            <section>
              <h2 className="text-3xl font-semibold text-white mb-6">1. Introduction</h2>
              <div className="text-white/80 space-y-4">
                <p>
                  At Everest Minds, we take your privacy seriously. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website and use our services. By using our services, you agree to the collection and use of information in accordance with this policy.
                </p>
                <p>
                  We are committed to protecting your personal information and your right to privacy. If you have any questions or concerns about this privacy policy or our practices with regard to your personal information, please contact us at privacy@everestminds.com.
                </p>
              </div>
            </section>

            <section>
              <h2 className="text-3xl font-semibold text-white mb-6">2. Information We Collect</h2>

              <div className="space-y-8">
                <div>
                  <h3 className="text-2xl font-semibold text-white mb-4">2.1 Personal Information</h3>
                  <p className="text-white/80 mb-4">
                    We may collect personal information that you voluntarily provide to us when you:
                  </p>
                  <ul className="list-disc pl-8 text-white/80 space-y-2">
                    <li>Register for our services or create an account</li>
                    <li>Sign up for our newsletter or marketing communications</li>
                    <li>Request customer support or technical assistance</li>
                    <li>Participate in surveys, promotions, or market research</li>
                    <li>Apply for employment opportunities</li>
                    <li>Contact us through various communication channels</li>
                  </ul>
                </div>

                <div>
                  <h3 className="text-2xl font-semibold text-white mb-4">2.2 Automatically Collected Information</h3>
                  <p className="text-white/80 mb-4">
                    When you visit our website or use our services, we automatically collect certain information, including:
                  </p>
                  <ul className="list-disc pl-8 text-white/80 space-y-2">
                    <li>Device information (browser type, operating system, device type)</li>
                    <li>Log data (IP address, access times, pages viewed)</li>
                    <li>Usage information (interactions with our services)</li>
                    <li>Location information (based on IP address)</li>
                    <li>Cookies and similar tracking technologies</li>
                  </ul>
                </div>
              </div>
            </section>

            <section>
              <h2 className="text-3xl font-semibold text-white mb-6">3. How We Use Your Information</h2>
              <p className="text-white/80 mb-4">We use the collected information for various purposes:</p>
              <ul className="list-disc pl-8 text-white/80 space-y-2">
                <li>Provide, maintain, and improve our services</li>
                <li>Personalize your experience and deliver tailored content</li>
                <li>Develop new products, services, and features</li>
                <li>Process transactions and send related information</li>
                <li>Send administrative information and important notices</li>
                <li>Communicate about promotions, updates, and news</li>
                <li>Monitor and analyze usage patterns and trends</li>
                <li>Detect, prevent, and address technical issues</li>
                <li>Protect against unauthorized access and abuse</li>
              </ul>
            </section>

            <section>
              <h2 className="text-3xl font-semibold text-white mb-6">4. Data Security</h2>
              <div className="text-white/80 space-y-4">
                <p>
                  We implement appropriate technical and organizational security measures to protect your information. Our security measures include:
                </p>
                <ul className="list-disc pl-8 space-y-2">
                  <li>Encryption of data in transit and at rest</li>
                  <li>Regular security assessments and penetration testing</li>
                  <li>Access controls and authentication mechanisms</li>
                  <li>Regular security training for employees</li>
                  <li>Incident response procedures</li>
                </ul>
                <p>
                  However, please note that no electronic transmission over the internet or information storage technology can be guaranteed to be 100% secure.
                </p>
              </div>
            </section>

            <section>
              <h2 className="text-3xl font-semibold text-white mb-6">5. Data Retention</h2>
              <div className="text-white/80 space-y-4">
                <p>
                  We retain personal information only for as long as necessary to fulfill the purposes for which it was collected, including:
                </p>
                <ul className="list-disc pl-8 space-y-2">
                  <li>Meeting legal and regulatory requirements</li>
                  <li>Resolving disputes</li>
                  <li>Enforcing our agreements</li>
                  <li>Maintaining business records</li>
                </ul>
              </div>
            </section>

            <section>
              <h2 className="text-3xl font-semibold text-white mb-6">6. Third-Party Services</h2>
              <div className="text-white/80 space-y-4">
                <p>
                  Our services may contain links to third-party websites and services. We are not responsible for the privacy practices or content of these third-party sites. We may also use third-party services for:
                </p>
                <ul className="list-disc pl-8 space-y-2">
                  <li>Analytics and performance monitoring</li>
                  <li>Payment processing</li>
                  <li>Email and communication services</li>
                  <li>Cloud hosting and storage</li>
                </ul>
                <p>
                  These third-party services have their own privacy policies, and we encourage you to review them.
                </p>
              </div>
            </section>

            <section>
              <h2 className="text-3xl font-semibold text-white mb-6">7. Your Rights</h2>
              <div className="text-white/80 space-y-4">
                <p>Depending on your location, you may have certain rights regarding your personal information:</p>
                <ul className="list-disc pl-8 space-y-2">
                  <li>Access your personal information</li>
                  <li>Correct inaccurate or incomplete information</li>
                  <li>Request deletion of your information</li>
                  <li>Object to processing of your information</li>
                  <li>Request data portability</li>
                  <li>Withdraw consent for processing</li>
                  <li>Lodge a complaint with supervisory authorities</li>
                </ul>
                <p>
                  To exercise any of these rights, please contact us using the information provided below.
                </p>
              </div>
            </section>

            <section>
              <h2 className="text-3xl font-semibold text-white mb-6">8. International Data Transfers</h2>
              <div className="text-white/80 space-y-4">
                <p>
                  We may transfer, store, and process your information in countries other than your own. We ensure appropriate safeguards are in place to protect your information when transferred internationally.
                </p>
              </div>
            </section>

            <section>
              <h2 className="text-3xl font-semibold text-white mb-6">9. Changes to This Privacy Policy</h2>
              <div className="text-white/80 space-y-4">
                <p>
                  We may update this privacy policy from time to time. We will notify you of any changes by posting the new policy on this page and updating the "Last updated" date.
                </p>
              </div>
            </section>

            <section>
              <h2 className="text-3xl font-semibold text-white mb-6">10. Contact Us</h2>
              <div className="text-white/80 space-y-4">
                <p>
                  If you have any questions about this Privacy Policy or our privacy practices, please contact us at:
                </p>
                <div className="pl-8">
                  <p>Email: privacy@everestminds.com</p>
                  <p>Address: 607 South tower, Cairo Business Plaza,</p>
                  <p>N 90 St, New Cairo, Cairo</p>
                  <p>Phone: +2 (2811) 80-53</p>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default PrivacyPolicy; 