import React from 'react';
import { ArrowRight } from 'lucide-react';
import { Link } from 'react-router-dom';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

// Sample success stories data - in a real app, this would come from an API or CMS
const successStories = [
  {
    id: 'iclaim',
    title: 'iClaim: Revolutionizing Cancer Care Access',
    subtitle: 'HEALTHCARE TRANSFORMATION',
    description: 'How we transformed cancer patient care in Egypt by reducing prescription approval times from 27 days to just 2 days.',
    image: 'https://images.unsplash.com/photo-1584982751601-97dcc096659c?auto=format&fit=crop&w=1200',
    stats: {
      waitTimeReduction: '93%',
      patientsImpacted: '1000s',
      citiesCovered: '27',
      approvalTime: '2 days'
    },
    category: 'Healthcare',
    client: 'Major Insurance Provider'
  },
  {
    id: 'medtrack',
    title: 'MedTrack: Digital Supply Chain Excellence',
    subtitle: 'SUPPLY CHAIN INNOVATION',
    description: 'Implementing blockchain technology to ensure medication authenticity and streamline pharmaceutical distribution.',
    image: 'https://images.unsplash.com/photo-1587370560942-ad2a04eabb6d?auto=format&fit=crop&w=1200',
    stats: {
      trackingAccuracy: '99.9%',
      supplierNetwork: '200+',
      warehousesCovered: '15',
      deliveryTime: '-45%'
    },
    category: 'Supply Chain',
    client: 'Leading Pharmaceutical Distributor'
  },
  {
    id: 'clinicai',
    title: 'ClinicAI: Smart Diagnosis Assistant',
    subtitle: 'AI IN HEALTHCARE',
    description: 'Leveraging artificial intelligence to assist doctors in rare disease diagnosis and treatment planning.',
    image: 'https://images.unsplash.com/photo-1576091160399-112ba8d25d1d?auto=format&fit=crop&w=1200',
    stats: {
      diagnosisAccuracy: '94%',
      timeReduction: '65%',
      casesAnalyzed: '50k+',
      specialtiesCovered: '12'
    },
    category: 'AI & Machine Learning',
    client: 'Network of Specialty Clinics'
  }
];

const SuccessStoryCard = ({ story }) => (
  <div className="group relative bg-white/5 rounded-2xl overflow-hidden hover:bg-white/10 transition-all duration-300">
    <div className="aspect-w-16 aspect-h-9 mb-6">
      <img
        src={story.image}
        alt={story.title}
        className="w-full h-full object-cover group-hover:scale-105 transition-transform duration-500"
      />
    </div>
    <div className="p-8">
      <div className="mb-4 inline-block py-2 px-4 bg-gradient-to-r from-[#00B8A9]/20 to-[#5BDB94]/10 rounded-full">
        <span className="text-[#00B8A9] font-semibold tracking-wider">{story.subtitle}</span>
      </div>
      <h3 className="text-2xl font-bold text-white mb-4">{story.title}</h3>
      <p className="text-white/70 mb-6">{story.description}</p>
      <div className="flex justify-between items-center">
        <span className="text-white/50 text-sm">{story.category}</span>
        <Link
          to={`/success-stories/${story.id}`}
          className="group inline-flex items-center text-[#5BDB94] hover:text-[#4cc584] transition-colors"
        >
          Read Case Study
          <ArrowRight className="ml-2 w-5 h-5 group-hover:translate-x-1 transition-transform" />
        </Link>
      </div>
    </div>
  </div>
);

const SuccessStories = () => {
  return (
    <div className="min-h-screen bg-[#0A1F44]">
      <Navbar />

      {/* Hero Section */}
      <section className="pt-32 pb-20 relative overflow-hidden">
        <div className="absolute inset-0">
          <div className="absolute top-20 right-0 w-[800px] h-[800px] bg-gradient-to-r from-[#00B8A9] to-[#5BDB94] rounded-full blur-[120px] opacity-10 animate-pulse-slow"></div>
        </div>
        <div className="container mx-auto px-6 relative">
          <div className="max-w-4xl">
            <div className="mb-6 inline-block py-2 px-4 bg-gradient-to-r from-[#00B8A9]/20 to-[#5BDB94]/10 rounded-full">
              <span className="text-[#00B8A9] font-semibold tracking-wider">SUCCESS STORIES</span>
            </div>
            <h1 className="text-4xl md:text-6xl font-bold text-white mb-8 leading-tight">
              Transforming Healthcare<br />
              <span className="text-transparent bg-clip-text bg-gradient-to-r from-[#00B8A9] to-[#5BDB94]">
                One Innovation at a Time
              </span>
            </h1>
            <p className="text-xl text-white/70 mb-8 leading-relaxed">
              Discover how we've helped healthcare organizations overcome challenges and achieve remarkable results through digital transformation.
            </p>
          </div>
        </div>
      </section>

      {/* Success Stories Grid */}
      <section className="py-20 relative">
        <div className="container mx-auto px-6">
          <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
            {successStories.map((story) => (
              <SuccessStoryCard key={story.id} story={story} />
            ))}
          </div>
        </div>
      </section>

      {/* CTA Section */}
      <section className="py-24 relative overflow-hidden">
        <div className="absolute inset-0">
          <div className="absolute inset-0 bg-gradient-to-r from-[#00B8A9]/20 via-[#5BDB94]/10 to-[#00B8A9]/20"></div>
        </div>
        <div className="container mx-auto px-6 relative">
          <div className="max-w-4xl mx-auto text-center">
            <h2 className="text-4xl font-bold text-white mb-8">
              Ready to Write Your Success Story?
            </h2>
            <p className="text-xl text-white/70 mb-12 leading-relaxed">
              Let's collaborate to transform your healthcare challenges into success stories.
            </p>
            <div className="flex gap-6 justify-center">
              <button className="group px-8 py-4 bg-[#00B8A9] text-white rounded-lg font-semibold hover:bg-[#00a598] transition-all flex items-center gap-2">
                Contact Us
                <ArrowRight className="w-5 h-5 group-hover:translate-x-1 transition-transform" />
              </button>
              <button className="px-8 py-4 border-2 border-[#5BDB94] text-[#5BDB94] rounded-lg font-semibold hover:bg-[#5BDB94]/10 transition-all">
                View More Stories
              </button>
            </div>
          </div>
        </div>
      </section>

      <Footer />

      <style jsx global>{`
        @keyframes pulse-slow {
          0%, 100% { opacity: 0.1; }
          50% { opacity: 0.2; }
        }
        .animate-pulse-slow {
          animation: pulse-slow 4s cubic-bezier(0.4, 0, 0.6, 1) infinite;
        }
      `}</style>
    </div>
  );
};

export default SuccessStories; 