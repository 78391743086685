import React from 'react';
import { ClipboardList, Search, BarChart, Users, FileText, Shield, Microscope, Share2 } from 'lucide-react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

const RegistryAnimation = () => (
  <div className="relative w-full h-[600px]">
    <svg width="100%" height="100%" viewBox="0 0 700 700">
      {/* Registry Data Flow Visualization */}
      <g className="animate-pulse-opacity">
        {/* Central Registry Hub */}
        <circle
          cx="350"
          cy="350"
          r="80"
          fill="#0A1F44"
          stroke="#5BDB94"
          strokeWidth="2"
          className="animate-pulse-scale"
        />
        <text x="350" y="340" textAnchor="middle" fill="#5BDB94" fontSize="16">
          Patient
        </text>
        <text x="350" y="360" textAnchor="middle" fill="#5BDB94" fontSize="16">
          Registry
        </text>

        {/* Data Collection Points */}
        {[0, 72, 144, 216, 288].map((angle, index) => {
          const radius = 200;
          const x = 350 + radius * Math.cos((angle * Math.PI) / 180);
          const y = 350 + radius * Math.sin((angle * Math.PI) / 180);

          return (
            <g key={index}>
              {/* Connection Lines */}
              <path
                d={`M350,350 L${x},${y}`}
                stroke="#5BDB94"
                strokeWidth="2"
                strokeDasharray="4,4"
                className="animate-dash-slow"
              />

              {/* Collection Points */}
              <circle
                cx={x}
                cy={y}
                r="40"
                fill="#0A1F44"
                stroke="#5BDB94"
                strokeWidth="2"
              />

              {/* Data Flow Animation */}
              <circle
                r="4"
                fill="#5BDB94"
                className="animate-flow-path"
              >
                <animateMotion
                  dur="3s"
                  repeatCount="indefinite"
                  path={`M${x},${y} L350,350`}
                />
              </circle>
            </g>
          );
        })}

        {/* Registry Data Labels */}
        <text x="200" y="250" fill="#5BDB94" fontSize="14">Clinical Data</text>
        <text x="500" y="250" fill="#5BDB94" fontSize="14">Patient Reports</text>
        <text x="550" y="450" fill="#5BDB94" fontSize="14">Research Data</text>
        <text x="150" y="450" fill="#5BDB94" fontSize="14">Lab Results</text>
        <text x="350" y="150" fill="#5BDB94" fontSize="14">Outcomes</text>
      </g>
    </svg>
  </div>
);

const FeatureCard = ({ title, description, icon: Icon }) => (
  <div className="p-6 bg-white rounded-lg shadow-lg hover:shadow-xl transition-shadow">
    <div className="w-12 h-12 rounded-lg bg-[#5BDB94]/10 flex items-center justify-center mb-4">
      <Icon className="w-6 h-6 text-[#5BDB94]" />
    </div>
    <h3 className="text-xl font-semibold text-[#0A1F44] mb-3">{title}</h3>
    <p className="text-gray-600">{description}</p>
  </div>
);

const RegistryTypeCard = ({ title, description, metrics }) => (
  <div className="p-6 bg-white rounded-lg shadow-lg hover:shadow-xl transition-shadow">
    <h3 className="text-xl font-semibold text-[#0A1F44] mb-3">{title}</h3>
    <p className="text-gray-600 mb-4">{description}</p>
    <div className="grid grid-cols-2 gap-4">
      {metrics.map((metric, index) => (
        <div key={index} className="text-center">
          <div className="text-2xl font-bold text-[#5BDB94]">{metric.value}</div>
          <div className="text-sm text-gray-500">{metric.label}</div>
        </div>
      ))}
    </div>
  </div>
);

const PatientRegistry = () => {
  return (
    <div className="min-h-screen bg-[#F8FAFC]">
      <Navbar />

      {/* Hero Section */}
      <section className="relative bg-[#0A1F44] py-20">
        <div className="container mx-auto px-6">
          <div className="grid md:grid-cols-2 gap-12 items-center">
            <div>
              <div className="mb-6 inline-block py-2 px-4 bg-[#5BDB94]/20 rounded-full">
                <span className="text-[#5BDB94] font-semibold">PATIENT REGISTRY</span>
              </div>
              <h1 className="text-4xl md:text-5xl font-bold text-white mb-6">
                Advanced Disease Registry Platform
              </h1>
              <p className="text-xl text-white/80 mb-8">
                Comprehensive patient data collection and management platform designed for rare diseases, clinical research, and specialized care workflows.
              </p>
              <button className="px-8 py-4 bg-[#5BDB94] text-[#0A1F44] rounded-lg font-semibold hover:bg-[#4cc584] transition-all">
                Request Demo
              </button>
            </div>
            <div className="relative">
              <RegistryAnimation />
            </div>
          </div>
        </div>
      </section>

      {/* Features Section */}
      <section className="py-20">
        <div className="container mx-auto px-6">
          <div className="text-center mb-16">
            <h2 className="text-3xl font-bold text-[#0A1F44] mb-4">
              Comprehensive Registry Features
            </h2>
            <p className="text-xl text-gray-600 max-w-2xl mx-auto">
              Everything you need to manage patient registries and drive clinical research
            </p>
          </div>

          <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8">
            <FeatureCard
              title="Data Collection"
              description="Customizable forms and workflows for comprehensive patient data capture"
              icon={ClipboardList}
            />
            <FeatureCard
              title="Smart Search"
              description="Advanced search capabilities across all registry data points"
              icon={Search}
            />
            <FeatureCard
              title="Analytics"
              description="Real-time insights and reporting on patient populations"
              icon={BarChart}
            />
            <FeatureCard
              title="Patient Portal"
              description="Secure patient access for data entry and outcome tracking"
              icon={Users}
            />
            <FeatureCard
              title="Research Tools"
              description="Built-in tools for clinical research and studies"
              icon={Microscope}
            />
            <FeatureCard
              title="Documentation"
              description="Automated report generation and document management"
              icon={FileText}
            />
            <FeatureCard
              title="Data Security"
              description="HIPAA-compliant security with role-based access control"
              icon={Shield}
            />
            <FeatureCard
              title="Data Sharing"
              description="Secure data sharing between authorized stakeholders"
              icon={Share2}
            />
          </div>
        </div>
      </section>

      {/* Registry Types Section */}
      <section className="py-20 bg-white">
        <div className="container mx-auto px-6">
          <div className="text-center mb-16">
            <h2 className="text-3xl font-bold text-[#0A1F44] mb-4">
              Specialized Disease Registries
            </h2>
            <p className="text-xl text-gray-600 max-w-2xl mx-auto">
              Purpose-built registries for different therapeutic areas
            </p>
          </div>

          <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
            <RegistryTypeCard
              title="Rare Disease Registry"
              description="Comprehensive data collection for rare disease research and treatment"
              metrics={[
                { value: "50+", label: "Disease Types" },
                { value: "10K+", label: "Patients" }
              ]}
            />
            <RegistryTypeCard
              title="Cancer Registry"
              description="Detailed cancer patient data tracking and outcome monitoring"
              metrics={[
                { value: "25+", label: "Cancer Types" },
                { value: "15K+", label: "Cases" }
              ]}
            />
            <RegistryTypeCard
              title="Clinical Trial Registry"
              description="Trial participant tracking and data management"
              metrics={[
                { value: "100+", label: "Active Trials" },
                { value: "5K+", label: "Participants" }
              ]}
            />
          </div>
        </div>
      </section>

      {/* Stats Section */}
      <section className="bg-[#0A1F44] py-20">
        <div className="container mx-auto px-6">
          <div className="grid md:grid-cols-4 gap-8 text-center">
            <div className="p-6">
              <div className="text-4xl font-bold text-[#5BDB94] mb-2">100+</div>
              <div className="text-white/80">Active Registries</div>
            </div>
            <div className="p-6">
              <div className="text-4xl font-bold text-[#5BDB94] mb-2">50K+</div>
              <div className="text-white/80">Patients Enrolled</div>
            </div>
            <div className="p-6">
              <div className="text-4xl font-bold text-[#5BDB94] mb-2">200+</div>
              <div className="text-white/80">Research Studies</div>
            </div>
            <div className="p-6">
              <div className="text-4xl font-bold text-[#5BDB94] mb-2">99.9%</div>
              <div className="text-white/80">Data Accuracy</div>
            </div>
          </div>
        </div>
      </section>

      {/* CTA Section */}
      <section className="py-20">
        <div className="container mx-auto px-6">
          <div className="bg-[#0A1F44] rounded-2xl p-12 text-center">
            <h2 className="text-3xl font-bold text-white mb-6">
              Ready to Start Your Patient Registry?
            </h2>
            <p className="text-xl text-white/80 mb-8 max-w-2xl mx-auto">
              Join leading healthcare organizations that use our platform to manage patient registries and advance medical research.
            </p>
            <button className="px-8 py-4 bg-[#5BDB94] text-[#0A1F44] rounded-lg font-semibold hover:bg-[#4cc584] transition-all">
              Get Started
            </button>
          </div>
        </div>
      </section>

      <style jsx global>{`
        @keyframes pulse-opacity {
          0%, 100% { opacity: 0.8; }
          50% { opacity: 0.2; }
        }
        @keyframes pulse-scale {
          0%, 100% { transform: scale(1); }
          50% { transform: scale(1.5); }
        }
        @keyframes dash-slow {
          to {
            stroke-dashoffset: 20;
          }
        }
        .animate-pulse-opacity {
          animation: pulse-opacity 2s infinite;
        }
        .animate-pulse-scale {
          animation: pulse-scale 2s infinite;
        }
        .animate-dash-slow {
          stroke-dasharray: 10;
          animation: dash-slow 3s linear infinite;
        }
        @keyframes flow-path {
          0% { opacity: 0; }
          50% { opacity: 1; }
          100% { opacity: 0; }
        }
        .animate-flow-path {
          animation: flow-path 3s infinite;
        }
      `}</style>

      <Footer />
    </div>
  );
};

export default PatientRegistry; 