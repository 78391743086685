import React from 'react';
import { useParams, Link } from 'react-router-dom';
import { Calendar, Clock, ArrowLeft, Share2, Linkedin, Twitter, Facebook } from 'lucide-react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

// Sample blog data - In a real app, this would come from an API
const blogPosts = [
  {
    id: 1,
    title: "The Future of Healthcare: AI and Machine Learning",
    content: `
      <p>Artificial Intelligence (AI) and Machine Learning (ML) are revolutionizing the healthcare industry in unprecedented ways. From diagnosis to treatment planning, these technologies are enhancing medical professionals' capabilities and improving patient outcomes.</p>
      
      <h2>The Impact of AI in Healthcare</h2>
      <p>Healthcare organizations worldwide are increasingly adopting AI-powered solutions to streamline operations, reduce costs, and improve patient care. Here are some key areas where AI is making a significant impact:</p>
      
      <ul>
        <li>Diagnostic Accuracy</li>
        <li>Treatment Planning</li>
        <li>Drug Discovery</li>
        <li>Patient Care Management</li>
      </ul>
      
      <h2>Machine Learning Applications</h2>
      <p>Machine Learning algorithms are being used to analyze vast amounts of healthcare data, identifying patterns and insights that would be impossible for humans to discover manually.</p>
      
      <blockquote>
        "AI and ML are not replacing healthcare professionals; they're empowering them to make better decisions and provide superior care."
      </blockquote>
      
      <h2>Future Prospects</h2>
      <p>As these technologies continue to evolve, we can expect to see even more innovative applications in healthcare. The future holds promising developments in:</p>
      
      <ul>
        <li>Personalized Medicine</li>
        <li>Predictive Analytics</li>
        <li>Remote Patient Monitoring</li>
        <li>Automated Administrative Tasks</li>
      </ul>
    `,
    category: "Technology",
    date: "Feb 15, 2024",
    readTime: "5 min read",
    image: "https://images.unsplash.com/photo-1576091160399-112ba8d25d1d?ixlib=rb-1.2.1&auto=format&fit=crop&w=1000&q=80",
    slug: "future-of-healthcare-ai",
    author: {
      name: "Dr. Sarah Johnson",
      role: "Healthcare Technology Specialist",
      image: "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&auto=format&fit=crop&w=200&q=80"
    }
  },
  // Add more blog posts as needed
];

const RelatedPostCard = ({ post }) => (
  <Link to={`/blog/${post.slug}`} className="group">
    <div className="bg-white/5 rounded-lg overflow-hidden hover:bg-white/10 transition-all duration-300">
      <div className="relative h-40 overflow-hidden">
        <img
          src={post.image}
          alt={post.title}
          className="w-full h-full object-cover transform group-hover:scale-105 transition-transform duration-300"
        />
      </div>
      <div className="p-4">
        <h3 className="text-lg font-semibold text-white group-hover:text-[#5BDB94] transition-colors">
          {post.title}
        </h3>
        <div className="flex items-center text-white/60 text-sm mt-2">
          <Calendar className="w-4 h-4 mr-2" />
          {post.date}
        </div>
      </div>
    </div>
  </Link>
);

const SingleBlogPage = () => {
  const { slug } = useParams();
  const post = blogPosts.find(p => p.slug === slug) || blogPosts[0]; // Fallback to first post if not found
  const relatedPosts = blogPosts.filter(p => p.id !== post.id).slice(0, 3);

  return (
    <div className="min-h-screen bg-[#0A1F44]">
      <Navbar />

      {/* Hero Section */}
      <div className="relative py-24 border-b border-white/10">
        <div className="container mx-auto px-6">
          <Link to="/blog" className="inline-flex items-center text-white/60 hover:text-[#5BDB94] mb-8 transition-colors">
            <ArrowLeft className="w-5 h-5 mr-2" />
            Back to Blog
          </Link>

          <div className="max-w-4xl mx-auto">
            <div className="flex items-center space-x-4 mb-6">
              <span className="px-3 py-1 bg-[#5BDB94] text-[#0A1F44] text-sm font-medium rounded-full">
                {post.category}
              </span>
              <div className="flex items-center text-white/60 text-sm">
                <Calendar className="w-4 h-4 mr-2" />
                {post.date}
                <Clock className="w-4 h-4 ml-4 mr-2" />
                {post.readTime}
              </div>
            </div>

            <h1 className="text-4xl md:text-5xl font-bold text-white mb-8">
              {post.title}
            </h1>

            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <img
                  src={post.author.image}
                  alt={post.author.name}
                  className="w-12 h-12 rounded-full mr-4"
                />
                <div>
                  <div className="text-white font-medium">{post.author.name}</div>
                  <div className="text-white/60 text-sm">{post.author.role}</div>
                </div>
              </div>

              <div className="flex items-center space-x-4">
                <button className="p-2 text-white/60 hover:text-[#5BDB94] transition-colors">
                  <Share2 className="w-5 h-5" />
                </button>
                <button className="p-2 text-white/60 hover:text-[#5BDB94] transition-colors">
                  <Linkedin className="w-5 h-5" />
                </button>
                <button className="p-2 text-white/60 hover:text-[#5BDB94] transition-colors">
                  <Twitter className="w-5 h-5" />
                </button>
                <button className="p-2 text-white/60 hover:text-[#5BDB94] transition-colors">
                  <Facebook className="w-5 h-5" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div className="container mx-auto px-6 py-16">
        <div className="max-w-4xl mx-auto">
          <div className="mb-16">
            <img
              src={post.image}
              alt={post.title}
              className="w-full h-[400px] object-cover rounded-xl mb-12"
            />

            <div className="prose prose-lg prose-invert max-w-none"
              dangerouslySetInnerHTML={{ __html: post.content }}
            />
          </div>

          {/* Related Posts */}
          <div className="border-t border-white/10 pt-16">
            <h2 className="text-2xl font-bold text-white mb-8">Related Articles</h2>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              {relatedPosts.map((post) => (
                <RelatedPostCard key={post.id} post={post} />
              ))}
            </div>
          </div>
        </div>
      </div>

      <Footer />

      <style jsx global>{`
        .prose {
          color: rgba(255, 255, 255, 0.7);
        }
        .prose h2 {
          color: white;
          font-size: 1.875rem;
          margin-top: 2.5rem;
          margin-bottom: 1.25rem;
        }
        .prose p {
          margin-bottom: 1.5rem;
        }
        .prose ul {
          margin-top: 1.25rem;
          margin-bottom: 1.25rem;
        }
        .prose li {
          margin-top: 0.5rem;
          margin-bottom: 0.5rem;
        }
        .prose blockquote {
          color: #5BDB94;
          border-left-color: #5BDB94;
          font-style: italic;
          padding-left: 1.5rem;
          margin: 2rem 0;
        }
      `}</style>
    </div>
  );
};

export default SingleBlogPage; 