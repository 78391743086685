import React, { useState, useRef } from 'react';
import { Mail, Phone, MapPin, Clock } from 'lucide-react';
import emailjs from '@emailjs/browser';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

const ContactUs = () => {
  const form = useRef();
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState({ show: false, success: false, message: '' });
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    subject: '',
    message: '',
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setStatus({ show: false, success: false, message: '' });

    try {
      const result = await emailjs.sendForm(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
        form.current,
        process.env.REACT_APP_EMAILJS_PUBLIC_KEY
      );

      if (result.text === 'OK') {
        setStatus({
          show: true,
          success: true,
          message: 'Thank you! Your message has been sent successfully.'
        });
        // Reset form
        setFormData({
          name: '',
          email: '',
          phone: '',
          subject: '',
          message: '',
        });
      }
    } catch (error) {
      setStatus({
        show: true,
        success: false,
        message: 'Sorry, there was an error sending your message. Please try again.'
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-[#0A1F44]">
      <Navbar />
      <main className="container mx-auto px-6 py-32">
        {/* Header Section */}
        <div className="text-center mb-16">
          <div className="inline-block py-2 px-4 bg-[#5BDB94]/10 rounded-full mb-6">
            <span className="text-[#5BDB94] font-semibold">GET IN TOUCH</span>
          </div>
          <h1 className="text-4xl font-bold text-white mb-4">Contact Our Team</h1>
          <p className="text-lg text-white/70 max-w-2xl mx-auto">
            Have questions about our solutions? Want to learn more about how we can help your healthcare organization? We'd love to hear from you.
          </p>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-12">
          {/* Contact Form */}
          <div className="bg-white/5 backdrop-blur-lg rounded-2xl border border-white/10 p-8">
            <h2 className="text-2xl font-semibold text-white mb-6">Send us a Message</h2>

            {/* Status Message */}
            {status.show && (
              <div className={`p-4 rounded-lg mb-6 ${status.success ? 'bg-green-500/20 text-green-200' : 'bg-red-500/20 text-red-200'}`}>
                {status.message}
              </div>
            )}

            <form ref={form} onSubmit={handleSubmit} className="space-y-6">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div>
                  <label htmlFor="name" className="block text-sm font-medium text-white/70 mb-2">
                    Full Name *
                  </label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    required
                    className="w-full px-4 py-2 bg-white/10 border border-white/20 rounded-lg focus:ring-2 focus:ring-[#5BDB94] focus:border-transparent text-white placeholder-white/50"
                    placeholder="John Doe"
                  />
                </div>
                <div>
                  <label htmlFor="email" className="block text-sm font-medium text-white/70 mb-2">
                    Email Address *
                  </label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                    className="w-full px-4 py-2 bg-white/10 border border-white/20 rounded-lg focus:ring-2 focus:ring-[#5BDB94] focus:border-transparent text-white placeholder-white/50"
                    placeholder="john@example.com"
                  />
                </div>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div>
                  <label htmlFor="phone" className="block text-sm font-medium text-white/70 mb-2">
                    Phone Number
                  </label>
                  <input
                    type="tel"
                    id="phone"
                    name="phone"
                    value={formData.phone}
                    onChange={handleChange}
                    className="w-full px-4 py-2 bg-white/10 border border-white/20 rounded-lg focus:ring-2 focus:ring-[#5BDB94] focus:border-transparent text-white placeholder-white/50"
                    placeholder="+1 (234) 567-8900"
                  />
                </div>
                <div>
                  <label htmlFor="subject" className="block text-sm font-medium text-white/70 mb-2">
                    Subject *
                  </label>
                  <input
                    type="text"
                    id="subject"
                    name="subject"
                    value={formData.subject}
                    onChange={handleChange}
                    required
                    className="w-full px-4 py-2 bg-white/10 border border-white/20 rounded-lg focus:ring-2 focus:ring-[#5BDB94] focus:border-transparent text-white placeholder-white/50"
                    placeholder="How can we help?"
                  />
                </div>
              </div>
              <div>
                <label htmlFor="message" className="block text-sm font-medium text-white/70 mb-2">
                  Message *
                </label>
                <textarea
                  id="message"
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  required
                  rows="4"
                  className="w-full px-4 py-2 bg-white/10 border border-white/20 rounded-lg focus:ring-2 focus:ring-[#5BDB94] focus:border-transparent text-white placeholder-white/50"
                  placeholder="Tell us about your needs..."
                ></textarea>
              </div>
              <button
                type="submit"
                disabled={loading}
                className={`w-full bg-[#5BDB94] text-[#0A1F44] font-semibold py-3 px-6 rounded-lg transition-colors duration-300 
                  ${loading ? 'opacity-70 cursor-not-allowed' : 'hover:bg-[#4cc283]'}`}
              >
                {loading ? 'Sending...' : 'Send Message'}
              </button>
            </form>
          </div>

          {/* Contact Information */}
          <div className="space-y-8">
            {/* Map */}
            <div className="bg-white/5 backdrop-blur-lg rounded-2xl border border-white/10 p-4 h-[300px] overflow-hidden">
              <iframe
                title="Everest Minds Location"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3456.7970963767454!2d31.4447493!3d29.9773835!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x145916f41ef902d7%3A0x6c2c1f5495ccf11e!2sCairo%20Business%20Plaza!5e0!3m2!1sen!2seg!4v1647856732245!5m2!1sen!2seg"
                width="100%"
                height="100%"
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
                className="rounded-xl"
              ></iframe>
            </div>

            {/* Contact Cards */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div className="bg-white/5 backdrop-blur-lg rounded-xl border border-white/10 p-6 hover:border-[#5BDB94]/30 transition-colors">
                <Mail className="w-6 h-6 text-[#5BDB94] mb-4" />
                <h3 className="text-lg font-semibold text-white mb-2">Email Us</h3>
                <a href="mailto:info@everestminds.com" className="text-white/70 hover:text-[#5BDB94] transition-colors">
                  info@everestminds.com
                </a>
              </div>
              <div className="bg-white/5 backdrop-blur-lg rounded-xl border border-white/10 p-6 hover:border-[#5BDB94]/30 transition-colors">
                <Phone className="w-6 h-6 text-[#5BDB94] mb-4" />
                <h3 className="text-lg font-semibold text-white mb-2">Call Us</h3>
                <a href="tel:+228118053" className="text-white/70 hover:text-[#5BDB94] transition-colors">
                  +2 (2811) 80-53
                </a>
              </div>
              <div className="bg-white/5 backdrop-blur-lg rounded-xl border border-white/10 p-6 hover:border-[#5BDB94]/30 transition-colors">
                <MapPin className="w-6 h-6 text-[#5BDB94] mb-4" />
                <h3 className="text-lg font-semibold text-white mb-2">Visit Us</h3>
                <p className="text-white/70">
                  607 South tower, Cairo Business Plaza,<br />
                  N 90 St, New Cairo, Cairo
                </p>
              </div>
              <div className="bg-white/5 backdrop-blur-lg rounded-xl border border-white/10 p-6 hover:border-[#5BDB94]/30 transition-colors">
                <Clock className="w-6 h-6 text-[#5BDB94] mb-4" />
                <h3 className="text-lg font-semibold text-white mb-2">Business Hours</h3>
                <p className="text-white/70">
                  Monday - Friday<br />
                  9:00 AM - 6:00 PM
                </p>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default ContactUs; 