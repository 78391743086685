import React, { useState, useEffect } from 'react';
import { X, ChevronRight, Settings } from 'lucide-react';
import { Link } from 'react-router-dom';

const CookieConsent = () => {
  const [showBanner, setShowBanner] = useState(false);
  const [showPreferences, setShowPreferences] = useState(false);
  const [preferences, setPreferences] = useState({
    essential: true, // Always true and cannot be changed
    performance: true,
    functional: true,
    targeting: false,
  });

  useEffect(() => {
    // Check if user has already made their choice
    const consent = localStorage.getItem('cookieConsent');
    if (!consent) {
      setShowBanner(true);
    }
  }, []);

  const handleAcceptAll = () => {
    setPreferences({
      essential: true,
      performance: true,
      functional: true,
      targeting: true,
    });
    saveCookiePreferences({
      essential: true,
      performance: true,
      functional: true,
      targeting: true,
    });
    setShowBanner(false);
  };

  const handleAcceptSelected = () => {
    saveCookiePreferences(preferences);
    setShowBanner(false);
    setShowPreferences(false);
  };

  const saveCookiePreferences = (prefs) => {
    localStorage.setItem('cookieConsent', 'true');
    localStorage.setItem('cookiePreferences', JSON.stringify(prefs));
    // Here you would typically trigger your analytics/tracking code based on preferences
  };

  const togglePreferences = () => {
    setShowPreferences(!showPreferences);
  };

  if (!showBanner && !showPreferences) return null;

  return (
    <>
      {/* Main Cookie Banner */}
      {showBanner && !showPreferences && (
        <div className="fixed bottom-0 left-0 right-0 bg-white shadow-lg z-50 border-t border-gray-200">
          <div className="container mx-auto px-6 py-6">
            <div className="flex flex-col md:flex-row items-start md:items-center justify-between gap-4">
              <div className="flex-1">
                <h3 className="text-lg font-semibold text-gray-900 mb-2">We value your privacy</h3>
                <p className="text-gray-600">
                  We use cookies to enhance your browsing experience, serve personalized content, and analyze our traffic. By clicking "Accept All", you consent to our use of cookies. Read our{' '}
                  <Link to="/cookie-policy" className="text-[#5BDB94] hover:underline">Cookie Policy</Link> to learn more.
                </p>
              </div>
              <div className="flex flex-col sm:flex-row gap-3 min-w-[300px]">
                <button
                  onClick={togglePreferences}
                  className="px-6 py-2.5 border border-gray-300 rounded-lg text-gray-700 hover:bg-gray-50 transition-colors flex items-center justify-center gap-2"
                >
                  <Settings size={18} />
                  Preferences
                </button>
                <button
                  onClick={handleAcceptAll}
                  className="px-6 py-2.5 bg-[#5BDB94] text-white rounded-lg hover:bg-[#4cc584] transition-colors flex items-center justify-center gap-2"
                >
                  Accept All
                  <ChevronRight size={18} />
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Detailed Preferences Modal */}
      {showPreferences && (
        <div className="fixed inset-0 bg-black/50 z-50 flex items-center justify-center p-4">
          <div className="bg-white rounded-xl shadow-xl max-w-2xl w-full max-h-[90vh] overflow-hidden">
            <div className="p-6 border-b border-gray-200">
              <div className="flex items-center justify-between">
                <h2 className="text-2xl font-semibold text-gray-900">Cookie Preferences</h2>
                <button
                  onClick={() => setShowPreferences(false)}
                  className="text-gray-400 hover:text-gray-500 transition-colors"
                >
                  <X size={24} />
                </button>
              </div>
            </div>

            <div className="p-6 overflow-y-auto max-h-[60vh]">
              <div className="space-y-6">
                {/* Essential Cookies */}
                <div className="flex items-start gap-4">
                  <div className="flex-1">
                    <div className="flex items-center gap-2">
                      <h3 className="text-lg font-semibold text-gray-900">Essential Cookies</h3>
                      <span className="px-2 py-1 text-xs bg-gray-100 text-gray-600 rounded">Always Active</span>
                    </div>
                    <p className="mt-1 text-gray-600">
                      These cookies are necessary for the website to function and cannot be switched off.
                    </p>
                  </div>
                  <div className="pt-1">
                    <input
                      type="checkbox"
                      checked={preferences.essential}
                      disabled
                      className="h-5 w-5 rounded border-gray-300 text-[#5BDB94] focus:ring-[#5BDB94]"
                    />
                  </div>
                </div>

                {/* Performance Cookies */}
                <div className="flex items-start gap-4">
                  <div className="flex-1">
                    <h3 className="text-lg font-semibold text-gray-900">Performance Cookies</h3>
                    <p className="mt-1 text-gray-600">
                      These cookies allow us to count visits and traffic sources to measure and improve site performance.
                    </p>
                  </div>
                  <div className="pt-1">
                    <input
                      type="checkbox"
                      checked={preferences.performance}
                      onChange={(e) => setPreferences({ ...preferences, performance: e.target.checked })}
                      className="h-5 w-5 rounded border-gray-300 text-[#5BDB94] focus:ring-[#5BDB94]"
                    />
                  </div>
                </div>

                {/* Functional Cookies */}
                <div className="flex items-start gap-4">
                  <div className="flex-1">
                    <h3 className="text-lg font-semibold text-gray-900">Functional Cookies</h3>
                    <p className="mt-1 text-gray-600">
                      These cookies enable personalized features and functionality.
                    </p>
                  </div>
                  <div className="pt-1">
                    <input
                      type="checkbox"
                      checked={preferences.functional}
                      onChange={(e) => setPreferences({ ...preferences, functional: e.target.checked })}
                      className="h-5 w-5 rounded border-gray-300 text-[#5BDB94] focus:ring-[#5BDB94]"
                    />
                  </div>
                </div>

                {/* Targeting Cookies */}
                <div className="flex items-start gap-4">
                  <div className="flex-1">
                    <h3 className="text-lg font-semibold text-gray-900">Targeting Cookies</h3>
                    <p className="mt-1 text-gray-600">
                      These cookies may be set by our advertising partners to build a profile of your interests.
                    </p>
                  </div>
                  <div className="pt-1">
                    <input
                      type="checkbox"
                      checked={preferences.targeting}
                      onChange={(e) => setPreferences({ ...preferences, targeting: e.target.checked })}
                      className="h-5 w-5 rounded border-gray-300 text-[#5BDB94] focus:ring-[#5BDB94]"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="p-6 border-t border-gray-200 bg-gray-50">
              <div className="flex flex-col sm:flex-row justify-end gap-3">
                <button
                  onClick={() => setShowPreferences(false)}
                  className="px-6 py-2.5 border border-gray-300 rounded-lg text-gray-700 hover:bg-gray-50 transition-colors"
                >
                  Cancel
                </button>
                <button
                  onClick={handleAcceptSelected}
                  className="px-6 py-2.5 bg-[#5BDB94] text-white rounded-lg hover:bg-[#4cc584] transition-colors"
                >
                  Save Preferences
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CookieConsent; 