import React from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

const CookiePolicy = () => {
  return (
    <div className="min-h-screen bg-[#0A1F44]">
      <Navbar />
      <div className="container mx-auto px-6 py-24">
        <div className="max-w-4xl mx-auto">
          <h1 className="text-5xl font-bold text-white mb-6">Cookie Policy</h1>
          <p className="text-white/80 mb-12 text-lg">Last updated: {new Date().toLocaleDateString()}</p>

          <div className="space-y-16">
            <section>
              <h2 className="text-3xl font-semibold text-white mb-6">1. Introduction</h2>
              <div className="text-white/80 space-y-4">
                <p>
                  This Cookie Policy explains how Everest Minds ("we", "us", and "our") uses cookies and similar technologies to recognize you when you visit our website. It explains what these technologies are and why we use them, as well as your rights to control our use of them.
                </p>
                <p>
                  By continuing to use our website, you are agreeing to our use of cookies as described in this Cookie Policy.
                </p>
              </div>
            </section>

            <section>
              <h2 className="text-3xl font-semibold text-white mb-6">2. What are Cookies?</h2>
              <div className="text-white/80 space-y-4">
                <p>
                  Cookies are small data files that are placed on your computer or mobile device when you visit a website. They are widely used by website owners to make their websites work, or work more efficiently, as well as to provide reporting information.
                </p>
                <p>
                  Cookies set by the website owner (in this case, Everest Minds) are called "first-party cookies". Cookies set by parties other than the website owner are called "third-party cookies". Third-party cookies enable third-party features or functionality to be provided on or through the website.
                </p>
              </div>
            </section>

            <section>
              <h2 className="text-3xl font-semibold text-white mb-6">3. Types of Cookies We Use</h2>
              <div className="text-white/80 space-y-8">
                <div>
                  <h3 className="text-2xl font-semibold text-white mb-4">3.1 Essential Cookies</h3>
                  <p className="mb-4">
                    These cookies are strictly necessary for the website to function and cannot be switched off in our systems. They are usually set in response to actions you take, such as:
                  </p>
                  <ul className="list-disc pl-8 space-y-2">
                    <li>Setting your privacy preferences</li>
                    <li>Logging in or filling in forms</li>
                    <li>Basic site functionality</li>
                  </ul>
                </div>

                <div>
                  <h3 className="text-2xl font-semibold text-white mb-4">3.2 Performance Cookies</h3>
                  <p className="mb-4">
                    These cookies allow us to count visits and traffic sources so we can measure and improve the performance of our site. They help us know:
                  </p>
                  <ul className="list-disc pl-8 space-y-2">
                    <li>Which pages are the most and least popular</li>
                    <li>How visitors move around the site</li>
                    <li>If visitors encounter any error messages</li>
                  </ul>
                </div>

                <div>
                  <h3 className="text-2xl font-semibold text-white mb-4">3.3 Functional Cookies</h3>
                  <p className="mb-4">
                    These cookies enable enhanced functionality and personalization, such as:
                  </p>
                  <ul className="list-disc pl-8 space-y-2">
                    <li>Remembering your preferences</li>
                    <li>Customizing content based on your choices</li>
                    <li>Interactive features</li>
                  </ul>
                </div>

                <div>
                  <h3 className="text-2xl font-semibold text-white mb-4">3.4 Targeting Cookies</h3>
                  <p className="mb-4">
                    These cookies may be set through our site by our advertising partners to:
                  </p>
                  <ul className="list-disc pl-8 space-y-2">
                    <li>Build a profile of your interests</li>
                    <li>Show you relevant adverts on other sites</li>
                    <li>Measure advertising effectiveness</li>
                  </ul>
                </div>
              </div>
            </section>

            <section>
              <h2 className="text-3xl font-semibold text-white mb-6">4. How to Control Cookies</h2>
              <div className="text-white/80 space-y-4">
                <p>
                  You can set or amend your web browser controls to accept or refuse cookies. If you choose to reject cookies, you may still use our website though your access to some functionality and areas of our website may be restricted.
                </p>
                <div className="space-y-2">
                  <p>You can control cookies through your browser settings:</p>
                  <ul className="list-disc pl-8 space-y-2">
                    <li>Chrome: Settings → Privacy and Security → Cookies and other site data</li>
                    <li>Firefox: Options → Privacy & Security → Enhanced Tracking Protection</li>
                    <li>Safari: Preferences → Privacy → Cookies and website data</li>
                    <li>Edge: Settings → Privacy, search, and services → Cookies</li>
                  </ul>
                </div>
              </div>
            </section>

            <section>
              <h2 className="text-3xl font-semibold text-white mb-6">5. Cookie Preferences</h2>
              <div className="text-white/80 space-y-4">
                <p>
                  You can manage your cookie preferences at any time through our Cookie Consent Manager. This allows you to:
                </p>
                <ul className="list-disc pl-8 space-y-2">
                  <li>Review the cookies we use</li>
                  <li>Control which categories of cookies you accept</li>
                  <li>Withdraw your consent for non-essential cookies</li>
                </ul>
                <button className="mt-4 px-6 py-3 bg-[#5BDB94] text-[#0A1F44] rounded-lg font-semibold hover:bg-[#4cc584] transition-all">
                  Manage Cookie Preferences
                </button>
              </div>
            </section>

            <section>
              <h2 className="text-3xl font-semibold text-white mb-6">6. Updates to This Policy</h2>
              <div className="text-white/80 space-y-4">
                <p>
                  We may update this Cookie Policy from time to time to reflect changes in technology, legislation, our operations, or as we otherwise determine is necessary or appropriate. Any such changes will become effective when we make the revised Cookie Policy available on or through our website.
                </p>
              </div>
            </section>

            <section>
              <h2 className="text-3xl font-semibold text-white mb-6">7. Contact Us</h2>
              <div className="text-white/80 space-y-4">
                <p>
                  If you have any questions about our use of cookies or this Cookie Policy, please contact us at:
                </p>
                <div className="pl-8">
                  <p>Email: privacy@everestminds.com</p>
                  <p>Address: 607 South tower, Cairo Business Plaza,</p>
                  <p>N 90 St, New Cairo, Cairo</p>
                  <p>Phone: +2 (2811) 80-53</p>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default CookiePolicy; 