import React, { useState } from 'react';
import { Search, Calendar, Clock, ArrowRight } from 'lucide-react';
import { Link } from 'react-router-dom';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

// Sample blog data - In a real app, this would come from an API
const blogPosts = [
  {
    id: 1,
    title: "The Future of Healthcare: AI and Machine Learning",
    excerpt: "Discover how artificial intelligence and machine learning are revolutionizing the healthcare industry...",
    category: "Technology",
    date: "Feb 15, 2024",
    readTime: "5 min read",
    image: "https://images.unsplash.com/photo-1576091160399-112ba8d25d1d?ixlib=rb-1.2.1&auto=format&fit=crop&w=1000&q=80",
    slug: "future-of-healthcare-ai"
  },
  {
    id: 2,
    title: "Understanding FHIR: A Complete Guide",
    excerpt: "Learn everything you need to know about FHIR (Fast Healthcare Interoperability Resources)...",
    category: "Healthcare IT",
    date: "Feb 12, 2024",
    readTime: "8 min read",
    image: "https://images.unsplash.com/photo-1576091160550-2173dba999ef?ixlib=rb-1.2.1&auto=format&fit=crop&w=1000&q=80",
    slug: "understanding-fhir-guide"
  },
  {
    id: 3,
    title: "Digital Health Platforms: The New Standard",
    excerpt: "How digital health platforms are becoming the new standard in modern healthcare delivery...",
    category: "Digital Health",
    date: "Feb 10, 2024",
    readTime: "6 min read",
    image: "https://images.unsplash.com/photo-1576091160399-112ba8d25d1d?ixlib=rb-1.2.1&auto=format&fit=crop&w=1000&q=80",
    slug: "digital-health-platforms"
  },
  // Add more blog posts as needed
];

const categories = [
  "All",
  "Technology",
  "Healthcare IT",
  "Digital Health",
  "AI & ML",
  "Industry News",
  "Case Studies"
];

const BlogCard = ({ post }) => (
  <Link to={`/blog/${post.slug}`} className="group">
    <div className="bg-white/5 rounded-xl overflow-hidden hover:bg-white/10 transition-all duration-300">
      <div className="relative h-48 overflow-hidden">
        <img
          src={post.image}
          alt={post.title}
          className="w-full h-full object-cover transform group-hover:scale-105 transition-transform duration-300"
        />
        <div className="absolute top-4 left-4">
          <span className="px-3 py-1 bg-[#5BDB94] text-[#0A1F44] text-sm font-medium rounded-full">
            {post.category}
          </span>
        </div>
      </div>
      <div className="p-6">
        <div className="flex items-center text-white/60 text-sm mb-3">
          <Calendar className="w-4 h-4 mr-2" />
          {post.date}
          <Clock className="w-4 h-4 ml-4 mr-2" />
          {post.readTime}
        </div>
        <h3 className="text-xl font-semibold text-white mb-3 group-hover:text-[#5BDB94] transition-colors">
          {post.title}
        </h3>
        <p className="text-white/70 mb-4">
          {post.excerpt}
        </p>
        <div className="flex items-center text-[#5BDB94] font-medium">
          Read More
          <ArrowRight className="w-4 h-4 ml-2 group-hover:translate-x-2 transition-transform" />
        </div>
      </div>
    </div>
  </Link>
);

const BlogsPage = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("All");

  const filteredPosts = blogPosts.filter(post => {
    const matchesSearch = post.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
      post.excerpt.toLowerCase().includes(searchTerm.toLowerCase());
    const matchesCategory = selectedCategory === "All" || post.category === selectedCategory;
    return matchesSearch && matchesCategory;
  });

  return (
    <div className="min-h-screen bg-[#0A1F44]">
      <Navbar />

      {/* Hero Section */}
      <div className="relative py-24 border-b border-white/10">
        <div className="container mx-auto px-6">
          <div className="max-w-2xl">
            <h1 className="text-4xl md:text-5xl font-bold text-white mb-6">
              Insights & Resources
            </h1>
            <p className="text-xl text-white/70">
              Stay updated with the latest trends, insights, and innovations in healthcare technology
              and digital transformation.
            </p>
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div className="container mx-auto px-6 py-16">
        {/* Search and Filter */}
        <div className="flex flex-col md:flex-row justify-between items-start md:items-center mb-12 space-y-6 md:space-y-0">
          <div className="relative w-full md:w-96">
            <Search className="absolute left-4 top-1/2 transform -translate-y-1/2 text-white/40 w-5 h-5" />
            <input
              type="text"
              placeholder="Search articles..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="w-full bg-white/5 border border-white/10 rounded-lg py-3 pl-12 pr-4 text-white placeholder-white/40 focus:outline-none focus:border-[#5BDB94] transition-colors"
            />
          </div>

          <div className="flex flex-wrap gap-3">
            {categories.map((category) => (
              <button
                key={category}
                onClick={() => setSelectedCategory(category)}
                className={`px-4 py-2 rounded-full text-sm font-medium transition-colors ${selectedCategory === category
                    ? 'bg-[#5BDB94] text-[#0A1F44]'
                    : 'bg-white/5 text-white/70 hover:bg-white/10'
                  }`}
              >
                {category}
              </button>
            ))}
          </div>
        </div>

        {/* Blog Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {filteredPosts.map((post) => (
            <BlogCard key={post.id} post={post} />
          ))}
        </div>

        {filteredPosts.length === 0 && (
          <div className="text-center py-12">
            <p className="text-white/70 text-lg">
              No articles found matching your criteria.
            </p>
          </div>
        )}
      </div>

      <Footer />
    </div>
  );
};

export default BlogsPage; 