import React from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

const Careers = () => {
  return (
    <div className="min-h-screen bg-[#F8FAFC]">
      <Navbar />
      <main className="container mx-auto px-6 py-16">
        <h1 className="text-4xl font-bold text-[#061631] mb-8">Careers at Everest Minds</h1>
        <p className="text-lg text-gray-600">
          Join our team of innovators and help shape the future of healthcare technology. Explore exciting opportunities at Everest Minds.
        </p>
        {/* Add more content here */}
      </main>
      <Footer />
    </div>
  );
};

export default Careers; 