import React from 'react';
import { Users, Brain, Rocket, Target, Award, BarChart } from 'lucide-react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

const StatCard = ({ number, label }) => (
  <div className="text-center p-6 bg-white/5 rounded-lg border border-white/10 hover:scale-105 transition-transform duration-300">
    <div className="text-4xl font-bold text-[#5BDB94] mb-2">{number}</div>
    <div className="text-white/70">{label}</div>
  </div>
);

const ImpactArea = ({ icon: Icon, title, description }) => (
  <div className="p-6 bg-white/5 rounded-lg border border-white/10 hover:border-[#5BDB94]/30 transition-all group">
    <div className="w-12 h-12 rounded-lg bg-[#5BDB94]/10 flex items-center justify-center mb-4 group-hover:bg-[#5BDB94]/20 transition-colors">
      <Icon className="w-6 h-6 text-[#5BDB94]" />
    </div>
    <h3 className="text-xl font-semibold text-white mb-3">{title}</h3>
    <p className="text-white/70">{description}</p>
  </div>
);

const AboutUs = () => (
  <div className="min-h-screen bg-[#0A1F44]">
    <Navbar />

    {/* Hero Section */}
    <section className="pt-32 pb-20 relative overflow-hidden">
      <div className="absolute inset-0">
        <div className="absolute top-20 right-0 w-[800px] h-[800px] bg-gradient-to-r from-[#5BDB94] to-[#00B8A9] rounded-full blur-[120px] opacity-10 animate-pulse-slow"></div>
      </div>
      <div className="container mx-auto px-6">
        <div className="grid md:grid-cols-2 gap-16 items-center">
          <div className="max-w-3xl">
            <div className="mb-6 inline-block py-2 px-4 bg-[#5BDB94]/10 rounded-full">
              <span className="text-[#5BDB94] font-semibold">ABOUT EVEREST MINDS</span>
            </div>
            <h1 className="text-5xl font-bold text-white mb-8">
              Engineering Intelligent Healthcare Solutions
            </h1>
            <p className="text-xl text-white/70">
              At Everest Minds, we engineer intelligent digital solutions that enhance healthcare access,
              streamline operations, and improve patient outcomes.
            </p>
          </div>
          <div className="relative">
            <img
              src="https://images.unsplash.com/photo-1516549655169-df83a0774514?auto=format&fit=crop&w=1200"
              alt="Healthcare Innovation"
              className="rounded-2xl shadow-2xl transform hover:scale-105 transition-all duration-500"
            />
          </div>
        </div>
      </div>
    </section>

    {/* Journey Section */}
    <section className="py-20 bg-white/5">
      <div className="container mx-auto px-6">
        <div className="grid md:grid-cols-2 gap-16 items-center">
          <div>
            <h2 className="text-3xl font-bold text-white mb-6">Our Journey Since 2017</h2>
            <p className="text-white/70 mb-6">
              We've worked at the intersection of healthcare and digital transformation, solving critical
              inefficiencies—from cutting treatment approval times from months to days to enabling seamless
              collaboration between healthcare professionals.
            </p>
            <p className="text-white/70">
              Our solutions are built to integrate with real-world healthcare systems, ensuring scalability,
              compliance, and lasting impact.
            </p>
          </div>
          <div className="relative">
            <img
              src="https://images.unsplash.com/photo-1504670073073-6123e39e0754?auto=format&fit=crop&w=1200"
              alt="Our Journey"
              className="rounded-2xl shadow-2xl mb-8 transform hover:scale-105 transition-all duration-500"
            />
            <div className="grid grid-cols-2 gap-6">
              <StatCard number="100K+" label="Patients Served" />
              <StatCard number="50K+" label="Cancer Patients Helped" />
              <StatCard number="100+" label="Healthcare Providers" />
              <StatCard number="6+" label="Years of Excellence" />
            </div>
          </div>
        </div>
      </div>
    </section>

    {/* What We Do Section */}
    <section className="py-20 relative overflow-hidden">
      <div className="absolute inset-0">
        <img
          src="https://images.unsplash.com/photo-1576091160399-112ba8d25d1d?auto=format&fit=crop&w=2000"
          alt="Healthcare Background"
          className="w-full h-full object-cover opacity-5"
        />
      </div>
      <div className="container mx-auto px-6 relative">
        <div className="text-center mb-16">
          <h2 className="text-3xl font-bold text-white mb-6">What We Do</h2>
          <p className="text-white/70 max-w-2xl mx-auto">
            We collaborate with healthcare and life sciences organizations across four key areas
          </p>
        </div>

        <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8">
          <ImpactArea
            icon={Brain}
            title="Market Access Intelligence"
            description="Digitizing healthcare policies and streamlining approval processes to accelerate patient access to treatments."
          />
          <ImpactArea
            icon={Users}
            title="HCP Engagement"
            description="Equipping physicians with data-driven insights and digital tools to enhance decision-making and collaboration."
          />
          <ImpactArea
            icon={Target}
            title="Patient Experience"
            description="Removing friction across the patient journey with intuitive, technology-driven care solutions."
          />
          <ImpactArea
            icon={BarChart}
            title="Operational Excellence"
            description="Automating workflows and optimizing healthcare operations for efficiency, compliance, and scalability."
          />
        </div>
      </div>
    </section>

    {/* Impact Section */}
    <section className="py-20 bg-white/5">
      <div className="container mx-auto px-6">
        <div className="text-center mb-16">
          <h2 className="text-3xl font-bold text-white mb-6">Our Impact</h2>
          <p className="text-white/70 max-w-2xl mx-auto">
            By combining strategic insight with advanced technology, we create digital health solutions
            that don't just meet industry standards—they set new benchmarks for efficiency and accessibility.
          </p>
        </div>

        <div className="grid md:grid-cols-3 gap-8">
          <div className="relative group">
            <img
              src="https://images.unsplash.com/photo-1579684385127-1ef15d508118?auto=format&fit=crop&w=800&h=400"
              alt="Patient Care"
              className="w-full h-48 object-cover rounded-t-lg"
            />
            <div className="text-center p-8 bg-white/5 rounded-b-lg border border-white/10 group-hover:border-[#5BDB94]/30 transition-all">
              <Award className="w-12 h-12 text-[#5BDB94] mx-auto mb-4" />
              <div className="text-4xl font-bold text-white mb-2">100K+</div>
              <div className="text-white/70">Patients served through our platforms</div>
            </div>
          </div>
          <div className="relative group">
            <img
              src="https://images.unsplash.com/photo-1584982751601-97dcc096659c?auto=format&fit=crop&w=800&h=400"
              alt="Healthcare Innovation"
              className="w-full h-48 object-cover rounded-t-lg"
            />
            <div className="text-center p-8 bg-white/5 rounded-b-lg border border-white/10 group-hover:border-[#5BDB94]/30 transition-all">
              <Rocket className="w-12 h-12 text-[#5BDB94] mx-auto mb-4" />
              <div className="text-4xl font-bold text-white mb-2">80%</div>
              <div className="text-white/70">Reduction in treatment approval times</div>
            </div>
          </div>
          <div className="relative group">
            <img
              src="https://images.unsplash.com/photo-1551076805-e1869033e561?auto=format&fit=crop&w=800&h=400"
              alt="Digital Healthcare"
              className="w-full h-48 object-cover rounded-t-lg"
            />
            <div className="text-center p-8 bg-white/5 rounded-b-lg border border-white/10 group-hover:border-[#5BDB94]/30 transition-all">
              <Target className="w-12 h-12 text-[#5BDB94] mx-auto mb-4" />
              <div className="text-4xl font-bold text-white mb-2">100+</div>
              <div className="text-white/70">Healthcare providers digitized</div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <Footer />

    <style jsx global>{`
      @keyframes pulse-slow {
        0%, 100% { opacity: 0.1; }
        50% { opacity: 0.2; }
      }
      .animate-pulse-slow {
        animation: pulse-slow 4s cubic-bezier(0.4, 0, 0.6, 1) infinite;
      }
    `}</style>
  </div>
);

export default AboutUs; 