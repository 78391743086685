import React from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

const TermsOfService = () => {
  return (
    <div className="min-h-screen bg-[#0A1F44]">
      <Navbar />
      <div className="container mx-auto px-6 py-24">
        <div className="max-w-4xl mx-auto">
          <h1 className="text-5xl font-bold text-white mb-6">Terms of Service</h1>
          <p className="text-white/80 mb-12 text-lg">Last updated: {new Date().toLocaleDateString()}</p>

          <div className="space-y-16">
            <section>
              <h2 className="text-3xl font-semibold text-white mb-6">1. Agreement to Terms</h2>
              <div className="text-white/80 space-y-4">
                <p>
                  Welcome to Everest Minds. These Terms of Service ("Terms") govern your access to and use of our website, products, and services. By accessing or using our services, you agree to be bound by these Terms and our Privacy Policy.
                </p>
                <p>
                  If you disagree with any part of these terms, you may not access or use our services. We reserve the right to modify these terms at any time, and we'll always post the most current version on our website.
                </p>
              </div>
            </section>

            <section>
              <h2 className="text-3xl font-semibold text-white mb-6">2. Intellectual Property Rights</h2>
              <div className="text-white/80 space-y-4">
                <p>
                  The Service and its original content, features, and functionality are owned by Everest Minds and are protected by international copyright, trademark, patent, trade secret, and other intellectual property laws.
                </p>
                <div className="space-y-2">
                  <p>Our content includes, but is not limited to:</p>
                  <ul className="list-disc pl-8 space-y-2">
                    <li>Software code and algorithms</li>
                    <li>User interface designs</li>
                    <li>Text, graphics, and images</li>
                    <li>Logos and trademarks</li>
                    <li>Audio and video content</li>
                    <li>Data and databases</li>
                  </ul>
                </div>
              </div>
            </section>

            <section>
              <h2 className="text-3xl font-semibold text-white mb-6">3. User Accounts</h2>
              <div className="text-white/80 space-y-4">
                <p>When you create an account with us, you must provide accurate, complete, and current information. Failure to do so constitutes a breach of the Terms, which may result in immediate termination of your account.</p>
                <div className="space-y-2">
                  <p>You are responsible for:</p>
                  <ul className="list-disc pl-8 space-y-2">
                    <li>Maintaining the confidentiality of your account credentials</li>
                    <li>All activities that occur under your account</li>
                    <li>Notifying us immediately of any unauthorized access</li>
                    <li>Ensuring your account information is accurate and up-to-date</li>
                  </ul>
                </div>
              </div>
            </section>

            <section>
              <h2 className="text-3xl font-semibold text-white mb-6">4. Acceptable Use</h2>
              <div className="text-white/80 space-y-4">
                <p>You agree not to use our Service:</p>
                <ul className="list-disc pl-8 space-y-2">
                  <li>In any way that violates any applicable laws or regulations</li>
                  <li>To transmit or upload any malicious code or viruses</li>
                  <li>To harvest or collect user information without permission</li>
                  <li>To impersonate or attempt to impersonate our company or employees</li>
                  <li>To interfere with or disrupt the service or servers</li>
                  <li>To engage in any fraudulent or deceptive practices</li>
                </ul>
              </div>
            </section>

            <section>
              <h2 className="text-3xl font-semibold text-white mb-6">5. Service Availability and Support</h2>
              <div className="text-white/80 space-y-4">
                <p>
                  We strive to ensure our services are available 24/7, but we cannot guarantee uninterrupted access. We reserve the right to modify, suspend, or discontinue any part of our services without notice.
                </p>
                <div className="space-y-2">
                  <p>Our support services include:</p>
                  <ul className="list-disc pl-8 space-y-2">
                    <li>Technical support during business hours</li>
                    <li>System maintenance and updates</li>
                    <li>Security monitoring and incident response</li>
                    <li>Documentation and user guides</li>
                  </ul>
                </div>
              </div>
            </section>

            <section>
              <h2 className="text-3xl font-semibold text-white mb-6">6. Payment Terms</h2>
              <div className="text-white/80 space-y-4">
                <p>
                  For paid services, you agree to provide current, complete, and accurate payment information. You agree to promptly update your account and payment information, including email address and payment method.
                </p>
                <div className="space-y-2">
                  <p>Our payment terms include:</p>
                  <ul className="list-disc pl-8 space-y-2">
                    <li>Automatic renewal unless cancelled in advance</li>
                    <li>Pro-rated refunds for cancelled services</li>
                    <li>Responsibility for all applicable taxes</li>
                    <li>Price changes with 30 days notice</li>
                  </ul>
                </div>
              </div>
            </section>

            <section>
              <h2 className="text-3xl font-semibold text-white mb-6">7. Limitation of Liability</h2>
              <div className="text-white/80 space-y-4">
                <p>
                  In no event shall Everest Minds be liable for any indirect, incidental, special, consequential, or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses.
                </p>
                <p>
                  We do not warrant that:
                </p>
                <ul className="list-disc pl-8 space-y-2">
                  <li>The service will be uninterrupted or error-free</li>
                  <li>Defects will be corrected immediately</li>
                  <li>The service will be free from harmful components</li>
                  <li>The results of using the service will meet your requirements</li>
                </ul>
              </div>
            </section>

            <section>
              <h2 className="text-3xl font-semibold text-white mb-6">8. Indemnification</h2>
              <div className="text-white/80 space-y-4">
                <p>
                  You agree to defend, indemnify, and hold harmless Everest Minds and its employees, contractors, agents, officers, and directors from any claims, damages, liabilities, costs, or expenses arising from your use of the service or violation of these Terms.
                </p>
              </div>
            </section>

            <section>
              <h2 className="text-3xl font-semibold text-white mb-6">9. Termination</h2>
              <div className="text-white/80 space-y-4">
                <p>
                  We may terminate or suspend your account and access to the Service immediately, without prior notice or liability, for any reason, including without limitation if you breach the Terms.
                </p>
                <p>
                  Upon termination:
                </p>
                <ul className="list-disc pl-8 space-y-2">
                  <li>Your right to use the service will cease immediately</li>
                  <li>We may delete your account and content</li>
                  <li>You remain liable for all amounts due</li>
                  <li>Certain provisions of the Terms will survive termination</li>
                </ul>
              </div>
            </section>

            <section>
              <h2 className="text-3xl font-semibold text-white mb-6">10. Governing Law</h2>
              <div className="text-white/80 space-y-4">
                <p>
                  These Terms shall be governed by and construed in accordance with the laws of Egypt, without regard to its conflict of law provisions.
                </p>
                <p>
                  Our failure to enforce any right or provision of these Terms will not be considered a waiver of those rights.
                </p>
              </div>
            </section>

            <section>
              <h2 className="text-3xl font-semibold text-white mb-6">11. Contact Us</h2>
              <div className="text-white/80 space-y-4">
                <p>
                  If you have any questions about these Terms, please contact us at:
                </p>
                <div className="pl-8">
                  <p>Email: legal@everestminds.com</p>
                  <p>Address: 607 South tower, Cairo Business Plaza,</p>
                  <p>N 90 St, New Cairo, Cairo</p>
                  <p>Phone: +2 (2811) 80-53</p>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default TermsOfService; 